import React, { Fragment } from 'react'
import Card from '@mui/material/Card'
import 'styles/Apps.style.scss'
import { appNames } from '../util/AppList'
import { connect } from 'react-redux'
import { CrossStorageHub } from 'cross-storage'
import PropTypes from 'prop-types'
import { startSession } from 'actions/session.action'
import {
  LOGISTICS_APP_HOST_URL,
  INSIGHTS_APP_HOST_URL,
  ACCESS_WEB_APP_HOST_URL,
  ASM_APP_HOST_URL,
  BI_LOGIN_URL,
  KOBO_URL,
  MIXPANEL_URL,
  TURN_IO_URL,
  CONNECT_APP_HOST_URL,
  LEARNING_MODULE_APP_HOST_URL
} from '../../lib/reach52AppsUrls'
import userRoles from '../util/static/userRoles.static'

CrossStorageHub.init([
  { origin: /.*reach52.*/, allow: ['get'] },
  { origin: /.*localhost:300\d$/, allow: ['get'] },
  { origin: /.*localhost:420\d$/, allow: ['get'] },
])

const Apps = ({ startSession, userInfo }) => {
  const { permission, id, userRole } = userInfo

  const loginApp = (appId) => {
    let appUrl
    switch (appId) {
      case 'logistics-pharma':
      case 'logistics-insurance':
        appUrl = LOGISTICS_APP_HOST_URL
        break
      case 'connect':
        appUrl = CONNECT_APP_HOST_URL
        break
      case 'access-web':
        appUrl = ACCESS_WEB_APP_HOST_URL
        break
      case 'asm':
        appUrl = ASM_APP_HOST_URL
        break
      case 'kobodashboard':
        appUrl = KOBO_URL
        break
      case 'bilogin':
        appUrl = BI_LOGIN_URL
        break
      case 'mixpanel':
        appUrl = MIXPANEL_URL
        break
      case 'turnio':
        appUrl = TURN_IO_URL
        break
      case 'insights':
        appUrl = INSIGHTS_APP_HOST_URL
        break
      case 'learningmodule':
        appId = 'connect'
        appUrl = LEARNING_MODULE_APP_HOST_URL
        break
      default:
        appUrl = ''
        break
    }
    startSession(appId, appUrl, id, false)
  }

  const checkRolePermission = (app) => {
    if (permission.includes(app.appId)) {
      let role = userRoles
        .filter((role) => role.appId === app.appId)
        .flatMap((val) => val.options)
      if (app.appId === 'logistics-pharma' && !userRole.includes('pharmacist'))
        return false
      if (
        app.appId === 'logistics-insurance' &&
        !userRole.includes('insurance')
      )
        return false
      if (role.length) {
        if (userRole.some((v) => role.map((o) => o.value).indexOf(v) !== -1)) {
          return true
        } else if (permission.includes('insights') && userRole.includes('app-user')) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }

  return (
    <Fragment>
      <div>
        <div className='flex-wrap'>
          {appNames.map((val, key) => {
            if (checkRolePermission(val)) {
              return (
                <Card
                  key={'card-' + key}
                  onClick={() => {
                    loginApp(val.appId)
                  }}
                  className='card'
                >
                  <span className='card-header background-sky'></span>
                  <span className='card-body'>{val.appName}</span>
                  <span className='card-icon'>{val.icon}</span>
                </Card>
              )
            } else {
              return false
            }
          })}
        </div>
      </div>
    </Fragment>
  )
}

Apps.propTypes = {
  startSession: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  userInfo: state.login.user,
})

export default connect(mapStateToProps, { startSession })(Apps)
