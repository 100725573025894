import { AT_SET_SUPPLIERS, AT_SET_INSURERS } from '../actions/types.actions'

const initialState = {
  insurers: [],
  suppliers: [],
}

const logisticsVendorsReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case AT_SET_INSURERS:
      return {
        ...state,
        insurers: payload,
      }
    case AT_SET_SUPPLIERS:
      return {
        ...state,
        suppliers: payload,
      }
    default:
      return state
  }
}
export default logisticsVendorsReducer
