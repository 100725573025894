import React from 'react'
import Person from '@mui/icons-material/Person'
import { CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import { NavMenus } from '../util/NavMenus'
import 'styles/Navbar.style.scss'
import 'styles/notification.style.scss'
import { logout } from 'actions/login.action'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Notification } from '../util/Notification'

const SideNavBar = ({ logout, isLoading, notification, userInfo }) => {
  const { firstName, lastName, userRole } = userInfo
  
  const Logout = () => {
    if (window.confirm("Are you sure do you want to Logout?") === true) {
      logout();
    } else {

    }
  }
  
  return (
    <>
      {isLoading ? <CircularProgress className='custom-loader' /> : ''}
      {notification.show ? (
        <div className='notification-container'>
          <Notification notification={notification} />
        </div>
      ) : (
        ''
      )}

      <div className='container-data'>
        <nav className='flex nav-menu active'>
          <div className='nav-header'>
            <Link to='#'>
              <Person sx={{ fontSize: 40 }} className='personIcon' />
              <div className='nav-header-title'>
                <span className='text-span'>
                  {firstName} {lastName}
                </span>
                <small className='text-small'>
                  {userRole.includes('super-admin')
                    ? 'Super-Admin'
                    : userRole.includes('vaccine-organisation')
                      ? 'Vaccine Organization'
                      : userRole.includes('vaccine-provider')
                        ? 'Vaccine Inventory'
                        : userRole.includes('vaccine-campaign-admin')
                          ? 'Vaccine Campaigner'
                          : 'App User'}
                </small>
              </div>
            </Link>
          </div>
          <div>
            <div className='nav-menu-items'>
              {NavMenus.map((item, index) => {
                if (item.title === 'Logout')
                  return (
                    <div key={index} className={item.cName}>
                    <Link to={item.path} onClick={Logout}>
                        {item.icon}
                        <span className='text-span'>{item.title}</span>
                      </Link>
                    </div>
                  )
                if (item.title === 'Manage Users') {
                  if (
                    (userRole.includes('super-admin') ||
                    userRole.includes('vaccine-organisation')) ||
                    ((userRole.includes('super-admin')  && userRole.includes('insights-super-admin')
                  ))) {
                    return (
                      <div key={index} className={item.cName}>
                        <Link to={item.path}>
                          {item.icon}
                          <span className='text-span'>{item.title}</span>
                        </Link>
                      </div>
                    )
                  } else return false
                }

                if (item.title === 'Organization Partners') {
                  if (
                    userRole.includes('super-admin')
                  ) {
                    return (
                      <div key={index} className={item.cName}>
                        <Link to={item.path}>
                          {item.icon}
                          <span className='text-span'>{item.title}</span>
                        </Link>
                      </div>
                    )
                  } else return false
                }

                if (item.title === 'Connect Manage Users') {
                  if (
                    userRole.includes('insights-super-admin')
                  ) {
                    return (
                      <div key={index} className={item.cName}>
                        <Link to={item.path}>
                          {item.icon}
                          <span className='text-span'>{item.title}</span>
                        </Link>
                      </div>
                    )
                  } else return false
                }
                return (
                  <div key={index} className={item.cName}>
                    <Link to={item.path}>
                      {item.icon}
                      <span className='text-span'>{item.title}</span>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

SideNavBar.propTypes = {
  logout: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  isLoading: state.loader.isLoading,
  notification: state.notification.notification,
  userInfo: state.login.user,
})

export default connect(mapStateToProps, { logout })(SideNavBar)
