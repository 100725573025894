import React from 'react'
import '../../styles/notification.style.scss'
import InfoIcon from '@mui/icons-material/Info'

export const Notification = ({ notification }) => {
  return (
    <div className={`notification__message ${notification.className}`}>
      <InfoIcon sx={{ fontSize: 17, paddingRight: 0.5 }} />
      {notification.message}
    </div>
  )
}
