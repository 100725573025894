import { combineReducers } from 'redux'
import login from './login.reducer'
import user from './user.reducer'
import alerts from './alert.reducer'
import loader from './loader.reducer'
import notification from './notification.reducer'
import logisticsVendors from './logistics-vendors.reducers'
import organizationPartners from './organization-partner.reducers'
import address from './address.reducers'
import campaigns from './campaigns.reducer'
import { LOGOUT } from 'actions/types.actions'

const appReducer = combineReducers({
  login,
  user,
  alerts,
  loader,
  notification,
  logisticsVendors,
  organizationPartners,
  campaigns,
  address
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer