let LOGISTICS_HOST_URL
let INSIGHTS_HOST_URL
let ACCESS_WEB_HOST_URL
let ASM_HOST_URL
let LOGISTICS_HOST_API_BASE_URL
let KOBO_DASHBOARD_URL
let ACCESS_WEB_HOST_API_BASE_URL
let CONNECT_URL
let CONNECT_API_BASE_URL
let STORAGE_MANAGEMENT_HOST_API_URL
let LEARNING_MODULE_URL

switch (process.env.REACT_APP_REACH52_CENTRALIZED_AUTHETICATION_APP_ENV) {
  case 'LIVE':
    LOGISTICS_HOST_URL = 'https://logistics.reach52.com/'
    INSIGHTS_HOST_URL = 'https://insights.reach52.com/'
    ACCESS_WEB_HOST_URL = 'https://accessweb.reach52.com/login'
    ASM_HOST_URL = 'https://asm.alliedworld.healthcare/'
    LOGISTICS_HOST_API_BASE_URL = 'https://logistics.reach52.com/'
    ACCESS_WEB_HOST_API_BASE_URL = 'https://accessweb.reach52.com/'
    KOBO_DASHBOARD_URL='https://kf.questions.reach52.com'
    CONNECT_URL='https://connect.reach52.com/'
    CONNECT_API_BASE_URL = 'https://connect-api.reach52.com/'
    STORAGE_MANAGEMENT_HOST_API_URL = 'https://storage-api.reach52.com'
    LEARNING_MODULE_URL = 'https://learning.reach52.com'
    break
  case 'DEMO':
    LOGISTICS_HOST_URL = 'https://demo-logistics.reach52.com/'
    INSIGHTS_HOST_URL = 'https://demo.insights.reach52.com'
    ACCESS_WEB_HOST_URL = 'https://demo-accessweb.reach52.com/login'
    ASM_HOST_URL = 'https://test-asm.alliedworld.healthcare/'
    LOGISTICS_HOST_API_BASE_URL = 'https://demo-logistics.reach52.com/'
    ACCESS_WEB_HOST_API_BASE_URL = 'https://demo-accessweb.reach52.com/'
    KOBO_DASHBOARD_URL='https://kf.demo-aqm.reach52.com'
    CONNECT_URL='https://demo.connect.reach52.com/'
    CONNECT_API_BASE_URL = 'https://demo-connect-api.reach52.com/'
    STORAGE_MANAGEMENT_HOST_API_URL = 'https://storage-api-demo.reach52.com'
    LEARNING_MODULE_URL = 'https://demo.learning.reach52.com'
    break
  case 'UAT':
    LOGISTICS_HOST_URL = 'https://uat-logistics.reach52.com/'
    INSIGHTS_HOST_URL = 'https://uat.insights.reach52.com'
    ACCESS_WEB_HOST_URL = 'https://uat-accessweb.reach52.com/login'
    ASM_HOST_URL = 'https://test-asm.alliedworld.healthcare/'
    LOGISTICS_HOST_API_BASE_URL = 'https://uat-logistics.reach52.com/'
    ACCESS_WEB_HOST_API_BASE_URL = 'https://uat-accessweb.reach52.com/'
    KOBO_DASHBOARD_URL='https://kf.kobo.reach52.org'
    CONNECT_URL='https://uat.connect.reach52.com/'
    CONNECT_API_BASE_URL = 'https://uat-connect-api.reach52.com/'
    STORAGE_MANAGEMENT_HOST_API_URL = 'https://storage-api-uat.reach52.com'
    LEARNING_MODULE_URL = 'https://uat.learning.reach52.com'
    break
  case 'DEV':
    LOGISTICS_HOST_URL = 'http://localhost:3000/'
    INSIGHTS_HOST_URL = 'http://localhost:4200/'
    ACCESS_WEB_HOST_URL = 'http://localhost:4200/login'
    ASM_HOST_URL = 'http://localhost:4200/'
    LOGISTICS_HOST_API_BASE_URL = 'http://localhost:8000/'
    ACCESS_WEB_HOST_API_BASE_URL = 'http://localhost:8020/'
    KOBO_DASHBOARD_URL='https://kf.kobo.reach52.org'
    CONNECT_URL='http://localhost:3007'
    CONNECT_API_BASE_URL = 'http://localhost:4000/'
    STORAGE_MANAGEMENT_HOST_API_URL = 'http://localhost:4004'
    LEARNING_MODULE_URL = 'http://127.0.0.1:4200'
    break
  default:
    LOGISTICS_HOST_URL = 'http://localhost:3000/'
    INSIGHTS_HOST_URL = 'http://localhost:4200/'
    ACCESS_WEB_HOST_URL = 'http://localhost:4200/login'
    ASM_HOST_URL = 'http://localhost:4200/'
    LOGISTICS_HOST_API_BASE_URL = 'http://localhost:8000/'
    ACCESS_WEB_HOST_API_BASE_URL = 'http://localhost:8020/'
    KOBO_DASHBOARD_URL='https://kobo.humanitarianresponse.info/accounts/login/?next=%2F%23%2F#/'
    CONNECT_URL='http://localhost:3007'
    CONNECT_API_BASE_URL = 'http://localhost:4000/'
    STORAGE_MANAGEMENT_HOST_API_URL = 'http://localhost:4004'
    LEARNING_MODULE_URL = 'http://127.0.0.1:4200'
    break
}

/*reach52 apps host urls*/
export const LOGISTICS_APP_HOST_URL = LOGISTICS_HOST_URL
export const INSIGHTS_APP_HOST_URL = INSIGHTS_HOST_URL
export const ACCESS_WEB_APP_HOST_URL = ACCESS_WEB_HOST_URL
export const ASM_APP_HOST_URL = ASM_HOST_URL
export const KOBO_URL = KOBO_DASHBOARD_URL
export const MIXPANEL_URL = 'https://mixpanel.com/login/'
export const BI_LOGIN_URL = 'https://app.powerbi.com/home'
export const TURN_IO_URL = 'https://whatsapp.turn.io/app/'
export const LOGISTICS_API_BASE_URL = LOGISTICS_HOST_API_BASE_URL
export const ACCESS_WEB_API_BASE_URL = ACCESS_WEB_HOST_API_BASE_URL
export const CONNECT_APP_HOST_URL = CONNECT_URL
export const CONNECT_APP_API_BASE_URL = CONNECT_API_BASE_URL
export const STORAGE_MANAGEMENT_API_URL = STORAGE_MANAGEMENT_HOST_API_URL
export const LEARNING_MODULE_APP_HOST_URL = LEARNING_MODULE_URL
