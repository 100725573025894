import { LOADING } from '../actions/types.actions'

const initialState = {
  isLoading: false
}

const loaderReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOADING:
      return {
        ...state,
        isLoading: payload,
      }

    default:
      return state
  }
}

export default loaderReducer
