import React, { Fragment, useState } from 'react'
import centralizedLogo from 'assets/reach52_logo_violet.png'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { login } from 'actions/login.action'
import { Link, Navigate } from 'react-router-dom'
import { TextField, Button, Alert } from '@mui/material'
import { INSIGHTS_APP_HOST_URL } from 'lib/reach52AppsUrls'
import { startSession } from 'actions/session.action'
import RedirectMessage from './RedirectMessage'

const Login = ({ login, startSession, isAuthenticated, alerts, user }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    appId: 'reach52-users',
  })

  const { username, password, appId } = formData

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  const onSubmit = (e) => {
    e.preventDefault()
    login(username, password, appId)
  }

  if (isAuthenticated) {
    if(user?.permission?.length === 2 && user?.permission?.includes('insights') && !user?.userRole?.includes('insights-super-admin')){
      startSession('insights', INSIGHTS_APP_HOST_URL, user.id, false)
      return <RedirectMessage>Please wait while you are being redirected...</RedirectMessage>
    }
    return <Navigate to='/dashboard' />
  }

  return (
    <Fragment>
      <div className='login-container'>
        <div className='login-form'>
          <div className='login-logo'>
            <h4 className='login-header'>
              <img src={centralizedLogo} alt='logo' />
            </h4>
          </div>
          <form onSubmit={onSubmit}>
            <TextField
              id='username'
              label='Username'
              variant='outlined'
              className='input'
              value={username}
              name='username'
              onChange={onChange}
            />
            <TextField
              id='Password'
              label='Password'
              variant='outlined'
              type='password'
              autoComplete='current-password'
              className='input'
              value={password}
              name='password'
              onChange={onChange}
            />
            {alerts &&
              alerts.map((alert) => (
                <Alert variant='outlined' severity='error' key={alert.id}>
                  {alert.msg}
                </Alert>
              ))}
            <div>
              <Button
                variant='contained'
                size='large'
                fullWidth
                onClick={onSubmit}
                type='submit'
                className='primaryButton'
              >
                Log in
              </Button>
              <div className='separator'>
                <Link className='custom-form-link' to='/forget-password'>
                  <span> Can't log in? </span>
                </Link>
              </div>
              <p className='version'>
                {
                  process.env
                    .REACT_APP_REACH52_CENTRALIZED_AUTHETICATION_APP_ENV
                }{' '}
                version{' '}
                {
                  process.env
                    .REACT_APP_REACH52_CENTRALIZED_AUTHETICATION_APP_VERSION
                }
              </p>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  )
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  user: PropTypes.object,
  alerts: PropTypes.array,
  startSession: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  user: state.login.user,
  alerts: state.alerts,
})

export default connect(mapStateToProps, { login, startSession })(Login)
