import { Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField,
  Switch, FormControlLabel } from '@mui/material'
  import React, { Fragment, useState } from 'react'
  import OrganizationTable from './OrganizationTable'
  import { Link } from 'react-router-dom'
  import { connect, useDispatch } from 'react-redux'
  import { debounce } from 'lodash'
  import { setRegion } from 'actions/organization-partner.action'
  import PropTypes from 'prop-types'
  import {AT_SET_NEW_ADDRESS} from 'actions/types.actions'

  const OrganizationPartner = ({setRegion, region}) => {
    const [filters, setFilters] = useState({
      countries: [],
      organizationTypes: [],
      searchKey: '',
    })
    const { organizationTypes, countries, searchKey } = filters
    const dispatch = useDispatch()
    const onSearchChange = debounce((evt) => {
      const { name, value } = evt.target
      setFilters({
        ...filters,
        [name]: value,
      })
    }, 500)
  
    const onFilterChange = (evt) => {
      evt.preventDefault()
      const { name, value } = evt.target
      setFilters({
        ...filters,
        [name]: value,
      })
    }

    const organizationTypesList = [{ value:'GP', label:'Growth Partner' },
    // { value:'CO', label:'Campaign Org' },
    { value:'MS', label:'Medicine Supplier' },
    { value:'IP', label:'Insurance Partner' },
    { value:'EP', label:'Event Partner' },
    // { value:'CT', label:'Connect Type' },
    { value:'EXP', label:'External Partner' }]

    const countryList = [
      { iso3: 'all', name: 'All' },
      { iso3: 'ind', name: 'India' },
      { iso3: 'phl', name: 'Philippines' },
      { iso3: 'khm', name: 'Cambodia' },
      { iso3: 'ken', name: 'Kenya' },
      { iso3: 'zaf', name: 'South Africa' }
    ]

    const handleChange = (event) => {
      setRegion(event.target.checked)
    }

    const setSelectedAddress = () => {
      dispatch({
        type: AT_SET_NEW_ADDRESS,
        payload: []
      })
    }

    return (
      <Fragment>
        <div className='body-container'>
          <h1 className='body-header-text'>Organization Partners</h1>
          <div className='body-header-actions '>
            <div className='left'>
              <Link className='custom-link' to='/organization-partners/register'>
                <Button
                  variant='contained'
                  onClick={() => {setSelectedAddress()}}
                  className='custom-button'
                >
                  Add Partner
                </Button>
              </Link>
              <TextField
                sx={{ m: 1, width: 250 }}
                id='searchKey'
                label='Search'
                variant='standard'
                className='input'
                name='searchKey'
                onChange={onSearchChange}
              />
            </div>
            <div className='right'>
              <FormControlLabel control={
                <Switch size="medium" checked={region} onChange={handleChange}></Switch>
              } label={ region ? "ALL" : "IDN" } />
              
              { region ? <FormControl variant='standard' sx={{ m: 1, width: 250 }}>
                <InputLabel id='demo-simple-select-autowidth-label'>
                  Countries
                </InputLabel>
                <Select
                  labelId='app-countries-checkbox-label'
                  id='app-countries-checkbox'
                  multiple
                  value={countries}
                  name='countries'
                  onChange={onFilterChange}
                  renderValue={(selected) => {
                    let selectedItems = []
                    countryList.forEach((obj) => {
                      if (selected.includes(obj.iso3)) {
                        selectedItems.push(obj.name)
                      }
                    })
                    return selectedItems.join(', ')
                  }}
                >
                  {countryList.map((value) => (
                    <MenuItem key={value.iso3} value={value.iso3}>
                      <Checkbox checked={countries?.indexOf(value.iso3) !== -1} />
                      <ListItemText primary={value.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> : <div/> }
              <FormControl variant='standard' sx={{ m: 1, width: 250 }}>
                <InputLabel id='demo-simple-select-autowidth-label'>
                  Organization Types
                </InputLabel>
                <Select
                  labelId='organization-types-checkbox-label'
                  id='organization-types-checkbox'
                  multiple
                  value={organizationTypes}
                  name='organizationTypes'
                  onChange={onFilterChange}
                  renderValue={(selected) => {
                    let selectedItems = []
                    organizationTypesList.forEach((obj) => {
                      if (selected.includes(obj.value)) {
                        selectedItems.push(obj.value)
                      }
                    })
                    return selectedItems.join(', ')
                  }}
                >
                  {organizationTypesList.map((value) => (
                    <MenuItem key={value.value} value={value.value}>
                      <Checkbox checked={organizationTypes?.indexOf(value.value) !== -1} />
                      <ListItemText primary={value.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <OrganizationTable
            organizationTypes={organizationTypes}
            filterCountries={countries}
            searchKey={searchKey}
          />
        </div>
      </Fragment>
    )
  }

  OrganizationPartner.propTypes = {
    setRegion: PropTypes.func.isRequired
  }
  
  const mapStateToProps = (state) => ({
    region: state.organizationPartners.region
  })
  
  export default connect(mapStateToProps, {
    setRegion
  })(OrganizationPartner)
  