/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-globals */
import React, { useEffect, Fragment } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import 'styles/user.table.scss'
import EditIcon from '@mui/icons-material/Edit'
import { getOrganizations, getOrganization } from 'actions/organization-partner.action'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import usePagination from 'components/util/Hooks/usePagination.hooks.js'
import Loader from '../util/Loader'
import { TablePagination } from '@mui/material'

const OrganizationTable = ({
  getOrganizations,
  organizationData,
  getOrganization,
  loading,
  organizationTypes,
  filterCountries,
  searchKey,
  region
}) => {
  const navigate = useNavigate()
  const [
    pageIndex,
    pageSize,
    tableData,
    setPageIndex,
    handleChangeRowsPerPage,
    handleChangePage
  ] = usePagination(organizationData)

  useEffect(() => {
    setPageIndex(0)
    getOrganizations({ organizationTypes: organizationTypes, countries: filterCountries, search: searchKey, region: region })
  }, [setPageIndex, filterCountries, organizationTypes, getOrganizations, searchKey, region])

  const countryList = {
    all: 'All',
    ind: 'India',
    idn: 'Indonesia',
    phl: 'Philippines',
    khm: 'Cambodia',
    ken: 'Kenya',
    zaf: 'South Africa'
  }

  const beautifyOperatingCountries = (countries) => {
    return countries.map(c => countryList[c]).join(', ');
  }

  return (
    <Fragment>
      {loading && <Loader show={loading} message='Getting Organizations' />}
      {!loading && (
        <Paper className='table-card-container'>
          <TableContainer className='table-container'>
            <Table
              sx={{ minWidth: 650 }}
              size='small'
              aria-label='a dense table'
            >
              <TableHead className='table-header-border'>
                <TableRow>
                  <TableCell className='table-header'>ID</TableCell>
                  <TableCell className='table-header'>type</TableCell>
                  <TableCell className='table-header'>Name</TableCell>
                  <TableCell className='table-header'>Auth. Person</TableCell>
                  <TableCell className='table-header'>Phone</TableCell>
                  <TableCell className='table-header'>Operating Countries</TableCell>
                  <TableCell className='table-header'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, idx) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{row.organizationId}</TableCell>
                    <TableCell>{row.organizationType}</TableCell>
                    <TableCell>{row.organizationName}</TableCell>
                    <TableCell>{row.authorizedPerson}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{beautifyOperatingCountries(row.operatingCountries)}</TableCell>

                    <TableCell>
                      <div className='edit-options-wrapper'>
                        <EditIcon
                          onClick={() => {
                            getOrganization(row._id, region,navigate, true)
                          }}
                          className='icon-color-primary edit-option-button'
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={organizationData.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      )}
    </Fragment>
  )
}

OrganizationTable.propTypes = {
  getOrganizations: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  organizationData: state.organizationPartners.organizationData,
  loading: state.organizationPartners.loading,
  region: state.organizationPartners.region
})

export default connect(mapStateToProps, {
  getOrganizations,
  getOrganization
})(OrganizationTable)
