import api from '../lib/api.lib'
import { USER_LOADED, LOGIN_SUCCESS, LOGOUT } from './types.actions'
import { setAlert } from './alert.action'
export const login = (username, password, appId) => async (dispatch) => {
  const body = { username, password, appId }

  try {
    const res = await api.post('/user/login', body)
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res['data'].data,
    })
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.status === 403) {
          dispatch(setAlert('User does not have enough privilege'))
        } else {
          dispatch(setAlert('Invalid username or password'))
        }
      } else {
        dispatch(setAlert('Invalid username or password'))
      }
    }
  }
}

export const loadUser = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/user/${id}`)

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    })
  } catch (error) {
    console.log(error)
  }
}

export const logout = () => ({ type: LOGOUT })
