import axios from 'axios'
import { AT_SET_CAMPAIGNS, LOADING } from './types.actions'
import { CONNECT_APP_API_BASE_URL } from '../lib/reach52AppsUrls'

export const getCampaigns = (selectedCountry) => async (dispatch) => {
  try {
    const params = {
      region: selectedCountry.toUpperCase() === 'IDN' ? 'IDN' : 'IND',
      country: selectedCountry.toUpperCase() === 'ALL' ? '' : selectedCountry.toUpperCase()
    }
    const api = axios.create({
      baseURL: CONNECT_APP_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          localStorage.token && localStorage.user
            ? `Bearer ${localStorage.token}`
            : '',
        authenticateduser: localStorage.user ? JSON.parse(localStorage.user).id : undefined
      },
    })
    const res = await api.get('campaign', { params: params })
    const result = res.data.data.rows
    if(result){
      dispatch({
        type: AT_SET_CAMPAIGNS,
        payload: result,
      })
      dispatch({
        type: LOADING,
        payload: false,
      })
    }
  } catch (error) {
    console.log(error)
  }
}
