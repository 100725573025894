import React, { Fragment, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SideNavBar from 'components/layout/SideNavBar'
import { closeSession, validateSession } from 'actions/session.action'

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  closeSession,
  validateSession
}) => {

  useEffect(() => {
    validateSession()
  }, [validateSession]);

  if (isAuthenticated) {
    return (
      <Fragment>
        <SideNavBar />
        <Component />
      </Fragment>
    )
  } else {
    closeSession()
    return <Navigate to='/' />
  }
}

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  closeSession: PropTypes.func.isRequired,
  validateSession: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  validateSession
})

export default connect(mapStateToProps, { closeSession, validateSession })(PrivateRoute)
