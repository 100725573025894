import React, { Fragment, useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import store from './store'
import './App.scss'
import LoginPage from './components/login/Login'
import DashboardPage from './components/admin/Dashboard'

import setAuthToken from './lib/setAuthToken.lib'
import UserList from './components/user/UserList'
import ConnectUserList from './components/user/ConnectUserList'
import UserForm from './components/user/UserForm'
import ConnectUserForm from './components/user/ConnectUserForm'
import PrivateRoute from './components/routing/PrivateRoute'
import { LOGOUT } from 'actions/types.actions'
import UserProfile from 'components/user/UserProfile'
import { HelpMenu } from 'components/user/HelpMenu'
import ResetPassword from 'components/login/ResetPassword'
import OrganizationPartner from 'components/organization/OrganizationPartner'
import OrganizationForm from 'components/organization/OrganizationForm'

const App = () => {
  useEffect(() => {
    if (localStorage.token && localStorage.user) {
      setAuthToken(localStorage.token, JSON.parse(localStorage.user))
    }

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token || !localStorage.user)
        store.dispatch({ type: LOGOUT })
    })
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Routes>
            <Route exact path='/' element={<LoginPage />} />
            <Route exact path='/forget-password' element={<ResetPassword />} />
            <Route path='/reset-password/:token' element={<ResetPassword />} />
            <Route
              path='/dashboard'
              element={<PrivateRoute component={DashboardPage} />}
            />
            <Route
              path='/users'
              element={<PrivateRoute component={UserList} />}
            />
            <Route
              path='/organization-partners'
              element={<PrivateRoute component={OrganizationPartner}/>}
            />
            <Route
              path='/organization-partners/register'
              element={<PrivateRoute component={OrganizationForm}/>}
            />
            <Route
              path='/organization-partners/edit/:partnerId'
              element={<PrivateRoute component={OrganizationForm}/>}
            />
            <Route
              path='/connect-users'
              element={<PrivateRoute component={ConnectUserList} />}
            />
            <Route
              path='/users/register'
              element={<PrivateRoute component={UserForm} />}
            />
            <Route
              path='/connect-users/register'
              element={<PrivateRoute component={ConnectUserForm} />}
            />
            <Route
              path='/users/profile/:userId'
              element={<PrivateRoute component={UserForm} />}
            />
            <Route
              path='/connect-users/profile/:userId'
              element={<PrivateRoute component={ConnectUserForm} />}
            />
            <Route
              path='/user/profile'
              element={<PrivateRoute component={UserProfile} />}
            />
            <Route
              path='/help'
              element={<PrivateRoute component={HelpMenu} />}
            />
          </Routes>
        </Fragment>
      </Router>
    </Provider>
  )
}

export default App
