import { useState, useEffect } from 'react'

function usePagination(inputData) {
  const [data, setData] = useState(inputData)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(5)

  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10))
    setPageIndex(0)
  }

  useEffect(() => {
    setData(inputData)
  }, [inputData])

  const tableData =
    pageSize > 0
      ? data.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize)
      : data
  return [
    pageIndex,
    pageSize,
    tableData,
    setPageIndex,
    handleChangeRowsPerPage,
    handleChangePage,
  ]
}
export default usePagination
