//note get appId from AppList
const userRoles = [
  {
    label: 'reach52 Users',
    appId: '',
    options: [
      {
        label: 'Super Admin',
        value: 'super-admin',
      },
      {
        label: 'App User',
        value: 'app-user',
      },
    ],
  },
  {
    label: 'Logistics',
    appId: 'logistics',
    options: [
      {
        label: 'Insurance',
        value: 'insurance',
      },
      {
        label: 'Pharmacist',
        value: 'pharmacist',
      },
    ],
  },
  {
    label: 'Access Web',
    appId: 'access-web',
    options: [
      {
        label: 'Pim',
        value: 'pim',
      },
    ],
  },
  {
    label: 'Connect Dashboard',
    appId: 'connect',
    options: [
      {
        label: 'Connect Admin',
        value: 'connect-admin',
      },
      {
        label: 'Manager',
        value: 'connect-manager',
      },
    ],
  },
  {
    label: 'Connect Management',
    appId: 'connect',
    options: [
      {
        label: 'Management Admin',
        value: 'management-admin',
      },
      {
        label: 'Finance',
        value: 'management-finance',
      },
      {
        label: 'Implementation Manager',
        value: 'management-implementation-manager',
      },
      {
        label: 'Tech Admin',
        value: 'management-tech-admin',
      },
      {
        label: 'System Admin',
        value: 'management-system-admin',
      },
      {
        label: 'Translator',
        value: 'management-translator',
      },
    ],
  },
  {
    label: 'Connect Vaccination',
    appId: 'connect',
    options: [
      {
        label: 'Vaccine Campaigner',
        value: 'vaccine-campaign-admin',
      },
      {
        label: 'Vaccine Organization',
        value: 'vaccine-organisation',
      },

      {
        label: 'Vaccine Inventory',
        value: 'vaccine-provider',
      },
    ],
  },
  {
    label: 'Insights',
    appId: 'insights',
    options: [
      {
        label: 'Insights Super Admin',
        value: 'insights-super-admin',
      },
      {
        label: 'Campaign',
        value: 'insights-campaign',
      },
    ],
  }
]

export default userRoles
