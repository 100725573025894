import React, { Fragment, useState, useEffect } from 'react'
import 'styles/User.form.styles.scss'
import 'styles/Main.body.styles.scss'
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import SideNavBar from 'components/layout/SideNavBar'
import { updateOrganization, createOrganization, getOrganization, openAddresModal } from 'actions/organization-partner.action'
import { connect, useDispatch } from 'react-redux'
import { checkErrorsForOrganizationForm } from 'components/util/validation/form.validation'
import { countries } from 'components/util/static/index.static'

import {
  TextField,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Button
} from '@mui/material'
import Loader from '../util/Loader'
import AddressModal from './AddressModal'
import {AT_SET_NEW_ADDRESS} from 'actions/types.actions'

const initialState = {
  organizationType: '',
  organizationName: '',
  organizationCode: '',
  catalogTags: '',
  deliveryFee: '',
  authorizedPerson: '',
  phone: '',
  email: '',
  addresses: [],
  operatingCountries: [],
  status: 'true',
  organizationNameTranslation: {
    eng: '',
    khm: '',
    kan: '',
    bah: '',
    swa: ''
  }
}

const fieldErrors = {
  organizationType: '',
  organizationName: '',
  organizationCode: '',
  catalogTags: '',
  deliveryFee: '',
  authorizedPerson: '',
  phone: '',
  email: '',
  addresses: [],
  operatingCountries: [],
  status: ''
}

const OrganizationForm = ({
  createOrganization,
  updateOrganization,
  getOrganization,
  loading,
  selectedOrganization,
  openAddresModal,
  isOpen,
  selectedAddress,
  region
}) => {
  const [organizationFormData, setOrganizationFormData] = useState({
    ...initialState,
    status: 'true'
  })
  
  const [organizationNameTranslationOriginal, setOrganizationNameTranslationOriginal] = useState({
    eng: '',
    khm: '',
    kan: '',
    bah: '',
    swa: ''
  })
  const availableLocales = [
    { key: 'eng', value: 'English'},
    { key: 'khm', value: 'Khmer'},
    { key: 'kan', value: 'Kannada'},
    { key: 'bah', value: 'Bahasa'},
    { key: 'swa', value: 'Swahili'}
  ]
  const [labelSelectedLocale, setLabelSelectedLocale] = useState('eng')
  const [organizationErrorField, setOrganizationErrorField] = useState(fieldErrors)
  const [errorMessage, setErrorMessage] = useState('')

  const { partnerId } = useParams()
  const creatingOrg = useMatch('/organization-partners/register')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const organizationTypesList = [{ value:'GP', label:'Growth Partner' },
  // { value:'CO', label:'Campaign Org' },
  { value:'MS', label:'Medicine Supplier' },
  { value:'IP', label:'Insurance Partner' },
  { value:'EP', label:'Event Partner' },
  { value:'EXP', label:'External Partner' }]

  const statusList = [ {value:"true", label:"Active"},{value:"false",label:"Inactive"}]
  const [isInsuranceAndMedicinePartnerSelected, setIsInsuranceAndMedicinePartnerSelected] = useState(false)
  const [isMedicalPartnerSelected, setIsMedicalPartnerSelected] = useState(false)

  if(creatingOrg) {
    loading = false
  }

  useEffect(() => {
    if (!selectedOrganization.partner && partnerId && !creatingOrg) {
      getOrganization(partnerId, region, navigate, false)
    }
    if (selectedOrganization.partner && partnerId) {
      const organizationPartner = { ...initialState }
      for (const key in selectedOrganization.partner) {
        if (key in organizationPartner) {
          organizationPartner[key] = selectedOrganization.partner[key]
        }
      }
      if(organizationPartner.organizationType === 'IP' || organizationPartner.organizationType === 'MS') {
        setIsInsuranceAndMedicinePartnerSelected(true)
        if(organizationPartner.organizationType === 'MS') {
          setIsMedicalPartnerSelected(true)
        }
      }
      if(organizationPartner.addresses.length > 0) {
        dispatch({
          type: AT_SET_NEW_ADDRESS,
          payload: organizationPartner.addresses
        })
      }
      if(selectedOrganization.lang){
        organizationPartner.organizationNameTranslation.eng = selectedOrganization.lang.ENG || selectedOrganization.lang.eng
        organizationPartner.organizationNameTranslation.khm = selectedOrganization.lang.KHM || selectedOrganization.lang.khm
        organizationPartner.organizationNameTranslation.kan = selectedOrganization.lang.KAN || selectedOrganization.lang.kan
        organizationPartner.organizationNameTranslation.bah = selectedOrganization.lang.BAH || selectedOrganization.lang.bah
        organizationPartner.organizationNameTranslation.swa = selectedOrganization.lang.SWA || selectedOrganization.lang.swa
        setOrganizationNameTranslationOriginal({
          eng : selectedOrganization.lang.ENG || selectedOrganization.lang.eng,
          khm : selectedOrganization.lang.KHM || selectedOrganization.lang.khm,
          kan : selectedOrganization.lang.KAN || selectedOrganization.lang.kan,
          bah : selectedOrganization.lang.BAH || selectedOrganization.lang.bah,
          swa : selectedOrganization.lang.SWA || selectedOrganization.lang.swa
        })
      }
      setOrganizationFormData(organizationPartner)
    }
  }, [
    selectedOrganization,
    getOrganization,
    partnerId,
    creatingOrg,
    region,
    dispatch,
    navigate
  ])

  const {
    organizationType,
    organizationName,
    organizationCode,
    catalogTags,
    deliveryFee,
    authorizedPerson,
    phone,
    email,
    addresses,
    operatingCountries,
    status,
    organizationNameTranslation
  } = organizationFormData

  const onChange = (evt) => {
    const { name, value } = evt.target
    setErrorMessage('')
    if(name === 'organizationType') {
      checkErrorsForOrganizationForm(evt.target, organizationErrorField, setOrganizationErrorField)
      if (value === 'MS' || value === 'IP') {   
        setIsInsuranceAndMedicinePartnerSelected(true)
        if(value === 'MS') {
          setOrganizationFormData({
            ...organizationFormData,
            [name]: value,
            organizationNameTranslation: {
              eng: '', bah: '', kan: '', khm: '', swa: ''
            }
          })
          setIsMedicalPartnerSelected(true)
        } else {
          setOrganizationFormData({
            ...organizationFormData,
            [name]: value,
            deliveryFee: '',
            organizationNameTranslation: {
              eng: '', bah: '', kan: '', khm: '', swa: ''
            }
          })
          setIsMedicalPartnerSelected(false)
        }
      } else {
        setOrganizationFormData({
          ...organizationFormData,
          [name]: value,
          deliveryFee: '',
          catalogTags: '',
          organizationCode: '',
          organizationNameTranslation: {
            eng: '', bah: '', kan: '', khm: '', swa: ''
          }
        })
        setIsInsuranceAndMedicinePartnerSelected(false)
        setIsMedicalPartnerSelected(false)
      }
    } else if(name === 'organizationNameTranslation') {
      if(labelSelectedLocale === 'eng') {
        checkErrorsForOrganizationForm({name:'organizationName', value: value}, organizationErrorField, setOrganizationErrorField)
      }
      organizationNameTranslation[labelSelectedLocale] = value
      setOrganizationFormData({
        ...organizationFormData,
        organizationName: organizationNameTranslation.eng
      })
    } else {
      checkErrorsForOrganizationForm(evt.target, organizationErrorField, setOrganizationErrorField)
      setOrganizationFormData({
        ...organizationFormData,
        [name]: value
      })
    }
  }

  const validate = (partner) => {
    let errorMessage = null

    if (!partner.organizationType) {
      errorMessage = 'Please choose the organization type'
    } else if (partner.addresses.length === 0) {
      errorMessage = 'Please add at least one address'
    } else if (!partner.authorizedPerson || partner.authorizedPerson.length < 3) {
      errorMessage = `Please input the authorized person's name (Minimum 3 letters)`
    } else if (partner.operatingCountries.length === 0) {
      errorMessage = 'Please choose atleast one country of operation'
    } else if (!partner.email) {
      errorMessage = 'Please provide a valid email address'
    } else if(partner.organizationType === 'IP' || partner.organizationType === 'MS') {
      if (!partner.organizationNameTranslation.eng || partner.organizationNameTranslation.eng.length < 3) {
        errorMessage = 'Please input the organization name (Minimum 3 letters)'
      } else if (!partner.organizationCode || partner.organizationCode.length < 3) {
        errorMessage = 'Please input the organization code (Minimum 3 letters)'
      } else if (!partner.catalogTags) {
        errorMessage = 'Please input the catalog tags with comma separated values'
      }
    } else if (!partner.organizationName || partner.organizationName.length < 3) {
      errorMessage = 'Please input the organization name (Minimum 3 letters)'
    } else if (!partner.phone) {
      errorMessage = 'Please input a valid phone number (Numbers only, between 5-15 digits)'
    } else if (partner.organizationType === 'MS') {
      if(!partner.deliveryFee || partner.deliveryFee.length > 5){
        errorMessage = 'Please input delivery fee not greater than 5 digits)'
      }
    }
    if(errorMessage){
      setErrorMessage(errorMessage)
      return false
    }
    return true
  }

  const onSubmit = (e) => {
    e.preventDefault()
    let organizationPartner = {
      ...organizationFormData,
      addresses: selectedAddress
    }
    if (validate(organizationPartner)) {
      creatingOrg
        ? createOrganization(organizationPartner, navigate, region)
        : updateOrganization(organizationPartner, partnerId, navigate, region)
    }
  }

  const deleteAddress = (index) => {
    if(selectedAddress.length > 0){
      selectedAddress.splice(index, 1)
      dispatch({
        type: AT_SET_NEW_ADDRESS,
        payload: selectedAddress
      })
      setOrganizationFormData({
        ...organizationFormData,
        addresses: selectedAddress
      })
    }
  }

  const showAddressModal = () =>{
    if(selectedAddress.length > 0) {
      setOrganizationFormData({
        ...organizationFormData,
        addresses: selectedAddress
      })
      openAddresModal(true, selectedAddress)
    } else {
      openAddresModal(true, addresses)
    }
  }

  return (
    <Fragment>
      <SideNavBar />
      <div className='body-container'>
        {loading &&
          <Loader show={loading} message="Getting Organization information"/>
        }
        {!loading && (
        <Fragment>
          {/* Start of code line */}
          <h1 className='body-header-text'>
            {creatingOrg ? 'Create ' : 'Update '} Partner
          </h1>

          <form className='user-form' onSubmit={onSubmit}>
            <div className='form-field'>
              <label className='form-label'>Organization Type</label>
              <FormControl sx={{ m: 1 }}>
                <Select
                  labelId='organization-type-checkbox-label'
                  id='organization-type-checkbox'
                  disabled={!creatingOrg}
                  value={organizationType}
                  name='organizationType'
                  onChange={onChange}
                  renderValue={(selected) => {
                    let selectedItems = []
                    organizationTypesList.forEach((obj) => {
                      if (selected.includes(obj.value)) {
                        selectedItems.push(obj.label)
                      }
                    })
                    return selectedItems.join(', ')
                  }}
                >
                  {organizationTypesList.map((value) => (
                    <MenuItem key={value.value} value={value.value}>
                      <Checkbox
                        checked={organizationType.indexOf(value.value) !== -1}
                      />
                      <ListItemText primary={value.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {organizationErrorField.organizationType && (
                <label className='validation-error'>
                  {organizationErrorField.organizationType}
                </label>
              )}
            </div>

            { !isInsuranceAndMedicinePartnerSelected ? <div className='form-field'>
              <label className='form-label'>Organization Name</label>
              <input
                disabled={!creatingOrg}
                className='input-default'
                name='organizationName'
                type='text'
                placeholder='Enter organization name'
                value={organizationName}
                onChange={onChange}
              />
              {organizationErrorField.organizationName && (
                <label className='validation-error'>
                  {organizationErrorField.organizationName}
                </label>
              )}
            </div> : null }

            { isInsuranceAndMedicinePartnerSelected ? <> 
              <div className='form-field'>
                <label className='form-label'>Organization Name</label>
                <input
                  disabled={!creatingOrg && organizationNameTranslationOriginal[labelSelectedLocale]}
                  className='input-default'
                  name='organizationNameTranslation'
                  type='text'
                  placeholder='Enter organization name'
                  value={organizationNameTranslation[labelSelectedLocale] ?? ''}
                  onChange={onChange}
                />
                <div className="locale-group">
                  { availableLocales.map((locale,index) => {
                    return ( <span title={locale.value} key={index} onClick={() => {setLabelSelectedLocale(locale.key)}}
                      className= { locale.key === labelSelectedLocale ? 'selected-locale' : organizationNameTranslation[locale.key] ? 'has-data': '' }>
                      { locale.key === labelSelectedLocale ? availableLocales[index].key : locale.key }
                    </span>)
                  })}
                </div>
                {organizationErrorField.organizationName && (
                  <label className='validation-error'>
                    {organizationErrorField.organizationName}
                  </label>
                )}
              </div>

              <div className='form-field'>
                <label className='form-label'>Organization Code</label>
                <input
                  disabled={!creatingOrg}
                  className='input-default'
                  name='organizationCode'
                  type='text'
                  placeholder='Enter organization code'
                  value={organizationCode}
                  onChange={onChange}
                />
                {organizationErrorField.organizationCode && (
                  <label className='validation-error'>
                    {organizationErrorField.organizationCode}
                  </label>
                )}
              </div>

              <div className='form-field'>
                <label className='form-label'>Catalog Tags</label>
                <input
                  className='input-default'
                  disabled={false}
                  name='catalogTags'
                  type='text'
                  autoComplete='none'
                  placeholder='Please enter comma separated values, eg: value1,value2,value3,...'
                  value={catalogTags}
                  onChange={onChange}
                />
                {organizationErrorField.catalogTags && (
                  <label className='validation-error'>
                    {organizationErrorField.catalogTags}
                  </label>
                )}
              </div>

              { isMedicalPartnerSelected ? <div className='form-field'>
                <label className='form-label'>Delivery Fee</label>
                <input
                  className='input-default'
                  disabled={false}
                  name='deliveryFee'
                  type='number'
                  placeholder='Enter delivery fee'
                  value={deliveryFee}
                  onChange={onChange}
                />
                {organizationErrorField.deliveryFee && (
                  <label className='validation-error'>
                    {organizationErrorField.deliveryFee}
                  </label>
                )}
              </div> : null}
              </>
              : null
            }

            <div className='form-field'>
              <label className='form-label'>Authorized Person</label>
              <input
                disabled={false}
                className='input-default'
                name='authorizedPerson'
                type='text'
                placeholder='Enter authorized person name'
                value={authorizedPerson}
                onChange={onChange}
              />
              {organizationErrorField.authorizedPerson && (
                <label className='validation-error'>
                  {organizationErrorField.authorizedPerson}
                </label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Phone Number</label>
              <input
                disabled={false}
                className='input-default'
                name='phone'
                type='text'
                placeholder='Enter phone number'
                value={phone}
                onChange={onChange}
              />
              {organizationErrorField.phone && (
                <label className='validation-error'>
                  {organizationErrorField.phone}
                </label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Email Address</label>
              <input
                disabled={false}
                name='email'
                type='text'
                placeholder='Enter email address'
                value={email}
                onChange={onChange}
              />
              {organizationErrorField.email && (
                <label className='validation-error'>{organizationErrorField.email}</label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Addresses</label>
              { selectedAddress.length > 0 ? 
              selectedAddress.map((address, id) => {
                return (
                  <div className='address-block' key={id}>
                    <div>
                      <div>{ address.level_5 }</div>
                      <div>{ address.level_4 }, { address.level_3 }</div>
                      <div>{ address.level_2 }, { address.level_1 }</div>
                      <div>Postal Code: { address.postalcode }</div>
                    </div>
                    <div>
                      <span className='delete-icon' onClick={() => {deleteAddress(id)}}>&times;</span>
                    </div> 
                  </div>
                )
              }) : null}

              <Button 
                onClick={showAddressModal}
                variant='contained'
                className='submit-button'
                disabled={selectedAddress.length>=5}>
                Add Address
              </Button>
              {isOpen ? <AddressModal /> : null}
              {organizationErrorField.operatingCountries && (
                <label className='validation-error'>
                  {organizationErrorField.operatingCountries}
                </label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Operating Countries</label>
              <FormControl sx={{ m: 1 }}>
                <Select
                  labelId='operating-countries-checkbox-label'
                  id='operating-countries-checkbox'
                  multiple
                  disabled={!creatingOrg && isInsuranceAndMedicinePartnerSelected}
                  value={operatingCountries}
                  name='operatingCountries'
                  onChange={onChange}
                  renderValue={(selected) => {
                    let selectedItems = []
                    countries.forEach((obj) => {
                      if (selected.includes(obj.iso3)) {
                        selectedItems.push(obj.name)
                      }
                    })
                    return selectedItems.join(', ')
                  }}
                >
                  {countries.map((country) => (
                    <MenuItem key={country.iso3} value={country.iso3}>
                      <Checkbox
                        checked={operatingCountries.indexOf(country.iso3) !== -1}
                      />
                      <ListItemText primary={country.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {organizationErrorField.operatingCountries && (
                <label className='validation-error'>
                  {organizationErrorField.operatingCountries}
                </label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Status</label>
              <TextField
                id='status'
                select
                name='status'
                value={status}
                onChange={onChange}
              >
                {statusList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {organizationErrorField.status && (
                <label className='validation-error'>
                  {organizationErrorField.status}
                </label>
              )}
            </div>

            <span className='form-field'>
              {errorMessage && (
                <label className='validation-error'>
                  {errorMessage}
                </label>
              )}
            </span>
            <Button
              onClick={onSubmit}
              variant='contained'
              className='submit-button'
            >
              {creatingOrg ? 'Create ' : 'Update'} Partner
            </Button>
          </form>
        {/* End of code line */}
        </Fragment> )} 
      </div>
    </Fragment>
  )
}

OrganizationForm.propTypes = {
  updateOrganization: PropTypes.func.isRequired,
  createOrganization: PropTypes.func.isRequired,
  openAddresModal: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  loading: state.organizationPartners.loading,
  selectedOrganization: state.organizationPartners.selectedOrganization,
  isOpen: state.organizationPartners.isOpen,
  selectedAddress: state.address.selectedAddress,
  region: state.organizationPartners.region
})

export default connect(mapStateToProps, {
  updateOrganization,
  createOrganization,
  getOrganization,
  openAddresModal
})(OrganizationForm)
