import React from 'react'
import {
  LocationOn,
  Equalizer,
  ViewCompact,
  StarRate,
  Public,
  TurnedIn,
  Favorite,
  MedicalServices,
} from '@mui/icons-material'
import 'styles/Apps.style.scss'

export const appNames = [
  {
    appName: 'Product Distribution',
    appId: 'logistics-pharma',
    icon: <MedicalServices className='color-default' />,
    cName: 'card-header background-sky',
  },
  {
    appName: 'Financial Services',
    appId: 'logistics-insurance',
    icon: <LocationOn className='color-default' />,
    cName: 'card-header background-sky',
  },
  {
    appName: 'Health Centres',
    appId: 'access-web',
    icon: <Public className='color-default' />,
    cName: 'card-header background-green',
  },
  {
    appName: 'ASM',
    appId: 'asm',
    icon: <TurnedIn className='color-default' />,
    cName: 'card-header background-voilet',
  },
  {
    appName: 'Connect',
    appId: 'connect',
    icon: <Equalizer className='color-default' />,
    cName: 'card-header background-sky',
  },
  {
    appName: 'App Engagement',
    appId: 'appEngagement',
    icon: <Favorite className='color-default' />,
    cName: 'card-header background-voilet',
  },
  {
    appName: 'Advanced Questions Module',
    appId: 'kobodashboard',
    icon: <ViewCompact className='color-default' />,
    cName: 'card-header background-voilet',
  },
  {
    appName: 'BI Insights',
    appId: 'bilogin',
    icon: <Equalizer className='color-default' />,
    cName: 'card-header background-sky',
  },
  {
    appName: 'App Analytics',
    appId: 'mixpanel',
    icon: <StarRate className='color-default' />,
    cName: 'card-header background-voilet',
  },
  {
    appName: 'Chatbots',
    appId: 'turnio',
    icon: <StarRate className='color-default' />,
    cName: 'card-header background-voilet',
  },
  {
    appName: 'Insights',
    appId: 'insights',
    icon: <Equalizer className='color-default' />,
    cName: 'card-header background-sky',
  },
  {
    appName: 'Learning Module',
    appId: 'learningmodule',
    icon: <Equalizer className='color-default' />,
    cName: 'card-header background-sky',
  }
]
