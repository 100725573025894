import { AT_SET_ADDRESS_LIST, AT_SET_AVAILABLE_OPTIONS,
    AT_SET_NEW_ADDRESS } from '../actions/types.actions'
    
const initialState = {
  addressList: [],
  isOpen: false,
  selectedAddress: [],
  loading: true,
  availableOptions: Array(5).fill([{id:'',name:''}])
}

const addressReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case AT_SET_ADDRESS_LIST:
      return {
        ...state,
        addressList: payload,
        loading: false
      }

    case AT_SET_AVAILABLE_OPTIONS:
      return {
        ...state,
        availableOptions: payload,
        loading: false
      }
    case AT_SET_NEW_ADDRESS:
      return {
        ...state,
        selectedAddress: payload
      }
    default:
      return state
  }
}

export default addressReducer
