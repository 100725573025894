const helpMenu = [
  {
    label: 'About',
    panelId: 'about',
    description: 'Centralized Authentication (CA) is the central access point for all reach52 applications ' +
    'like Financial Services, Product Distribution, Insights, and Health Centres. The application aims to ' +
    'provide better user management, performance & security.',
    options: []
  },
  {
    label: 'Centralized Auth Users',
    panelId: 'reach52-users',
    description:`reach52 Users (Centralized Auth Users) are the main users of the application, who can ` +
    `log in to the application and perform various activities. This includes accessing other reach52 ` +
    `apps & performing Create/Read/Update/Delete operations on other reach52 CA users. To access the ` +
    `CA app, the User should have "reach52 Users" App Permission. There are two types of reach52 ` +
    `users described below:`,
    options: [
      {
        label: 'App User',
        value: 'app-user',
        description: 'App Users can log in to CA and access the reach52 apps that they have been ' +
        'granted access to. App Users can update their profile details from the profile menu.'
      },
      {
        label: 'Super Admin',
        value: 'super-admin',
        description: `Super Admin has all the access privileges of App Users, and can also manage ` +
        `other App Users' & Super Admins' account details. Super Admins can: \n` +
        ` - View all App Users and Super Admins, \n` +  
        ` - Create new App Users or Super Admin accounts, \n` + 
        ` - Update other App Users' & Super Admins' account details, and \n` +
        ` - Delete other App Users' & Super Admins' accounts. \n`
      }
    ]
  },
  {
    label: 'Logistics',
    panelId: 'logistics',
    description: 'The reach52 Logistics web application is comprised of the Financial Services & ' +
    'Product Distribution web modules. The Financial Services module is used to manage/track insurance ' +
    'orders, while Product Distribution is for managing/tracking medicine orders.',
    options: [
      {
        label: 'Insurance',
        value: 'insurance',
        description:
          'The Financial Services module requires the user to have app permissions for Logistics - ' +
          'Insurance & an Insurance user role to view/update insurance orders from the selected insurer. ' +
          'The Super Admin also has to select the Insurer that the user belongs to & the Insurer role the user has.'
      },
      {
        label: 'Pharmacist',
        value: 'pharmacist',
        description: 'The Product Distribution module requires the user to have app permissions for Product ' +
        'Distribution & a Pharmacist user role to view/update Supplier orders from the selected Suppliers. ' +
        'The Super Admin also has to select the Supplier that the user belongs to & the Supplier role the user has.'
      }
    ]
  },
  {
    label: 'Health Centres',
    panelId: 'acess-web',
    description: 'The Health Centres module is also known as the Access Web module. It is used to manage ' +
    'information articles, create events & manage medical appointments.',
    options: [
      {
        label: 'Pim',
        value: 'pim',
        description:
          'To access the Health Centres module, the user must have app permissions for the Health Centres ' +
          'module, a PIM user role, and links to at least one organization.'
      }
    ]
  },
  {
    label: 'Connect',
    panelId: 'connect',
    description: 'reach52 Connect application is divided into 3 modules: Community Dashboard, ' +
    'Campaigns Management, and Vaccine Management.',
    options: [
      {
        label: 'Community Dashboard',
        value: 'community-dashboard',
        description: `The Community Dashboard is the primary module of Connect application. This module ` +
        `is divided into 3 modules: Data, Agents and Residents. \n` +
        `The data page is a dashboard that gives connect on top performing agents, no. of agents by ` +
        `country, new profiles per week, no. of residents per agent and per country, among other statistics. \n` +
        `The agent's module shows the full list of agents per country, including details such as email and total ` +
        `residents profiled on the dashboard. Individual agent profiles can be accessed and details such as ` +
        `assigned village, organization and PIN can be edited. \n` +
        `The resident's module shows every resident registered on the platform, including name, phone, country ` +
        `and agent who registered him / her.`,
        options: [
          {
            label: 'Connect Admin',
            value: 'connect-admin',
            description: 'Users with the Connect Admin role can use all features of the Community Dashboard module.'
          },
        ]
      },
      {
        label: 'Campaign Management',
        value: 'campaign-management',
        description: 'Campaign Management includes various sub-modules like Campaign, Resident and Questionnaire. ' +
        'This module is used to create & manage master data for reach52 access mobile application.',
        options: [
          {
            label: 'Management Admin',
            value: 'management-admin',
            description: 'Users with the Management Admin role can use features like Campaign, Engagement, Trainings, ' +
            'Topics, the Residents sub-module, and the Questionnaire sub-module in the Campaigns Management module.'
          },
          {
            label: 'System Admin',
            value: 'system-admin',
            description: 'Users with the System Admin role can access features like Organization Partners, ' +
            'Address and Questionnaire with creator access from the Campaigns Management module.'
          },
          {
            label: 'Translator',
            value: 'management-translator',
            description: 'Users with the translator role can change the translations in the MQM module.'
          },
          {
            label: 'Tech Admin',
            value: 'tech-admin',
            description: 'Users with the Tech Admin role can use Bulk Import feature from the ' +
            'Campaigns Management module. This allows users to import health log and medicine data into the system.'
          },
          {
            label: 'Finance',
            value: 'management-finance',
            description: 'Users with the Finance role can use the Engagement Finance features ' +
            'in Campaigns Management. They can approve payments to agents for verified residents.'
          },
        ]
      },
      {
        label: 'Vaccine Management',
        value: 'vaccine-management',
        description: 'The vaccine management module is used to manage & track vaccine-related data, ' +
        'including vaccine programs, vaccine inventory, vaccinated residents, and vaccination centres ' +
        '(location). Users with the following user roles can access this module to perform create/read/'+
        'update/delete operations.',
        options: [
          {
            label: 'Vaccine Organization',
            value: 'vaccine-organisation',
            description: 'Vaccine Organisation Admins are responsible for managing/creating vaccine programs by country. ' +
            'Users with the Vaccine Organization role can access vaccine organization admin features in the Vaccine Management module.'
          }
        ]
      },
      {
        label: 'MQM Module',
        value: 'mqm-module',
        description: `The MQM module enables dynamic form creation or editing. Users with the 'System Admin' role can ` +
        `view, edit or create forms and send them for review. A form that has been sent for review can be ` +
        `approved/rejected by any CA users with the role of 'Management Admin'.`
      }
    ]
  },
  {
    label: 'Insights',
    panelId: 'insights', 
    description:'reach52 Insights gives partners insights on campaigns and engagements that are carried ' +
    'out in reach52’s countries. This application has 6 main modules: My Campaign, News & Views, Gallery, ' +
    'Expand Campaign, Campaign, and Partners. The external/client user will mainly use this application to ' +
    'view updates on their funded campaigns and can also raise requests to connect admin to expand existing ' +
    'campaigns for their respective addresses or in new geographies. The following user types can access ' +
    'this application',
    options: [
      {
        label: 'Insights Super Admin',
        value: 'insights-super-admin',
        description: 'Connect Super Admins have all the access privileges of External Users & Connect ' +
        `Campaign Read/Write Roles. In addition, Connect Super Admins can manage the External Users' ` +
        'account details. Connect Super Admin can view, create and delete Connect Campaign users or ' +
        'External Users, and edit their account details.'
      },
      {
        label: 'Campaign Read Role',
        value: 'insights-campaign-read',
        description: 'Campaign Users with the Read role can log in to the Centralized Auth app and start ' +
        'the session for the reach52 connect app. After login, Campaign Users will be redirected to the ' +
        'reach52 connect page and perform various read operation on the Campaign and Partners modules. ' +
        'Also, these users can update their profile details from the profile menu.'
      },
      {
        label: 'Campaign Read/Write Role',
        value: 'insights-campaign-read-write',
        description: 'Campaign Users with the Read/Write role can log in to the Centralized Auth app and ' +
        'start the session for the reach52 connect app. After login, Campaign Users will be redirected to ' +
        'the reach52 connect page, seeing the Campaign and Partners modules. From the Campaign module, they ' +
        'will view all campaigns they are assigned to update - the view of each campaign will mostly be like ' +
        'that of the external user, except for the showing/hiding of some buttons. These users can add posts ' +
        'to update External Users on the progress of their funded campaigns. These users can update their ' +
        'profile details from the profile menu.'
      },
      {
        label: 'External User',
        value: 'app-external-user',
        description: 'External Users can log in and are taken directly to the My Campaign page, to view the ' +
        'campaigns they are assigned to. These users can only access My Campaign, News & Views, Gallery, and ' +
        'Expand Campaign. They also can update their profile details from the profile menu, on the top right.'
      }
    ]
  },
  {
    label: 'Learning Module',
    panelId: 'learning-module',
    description:'Create courses and modules to be shown in the android app. This will be used for training the agents.',
    options: []
  },
  {
    label: 'Third Party Apps',
    panelId: 'third-party-app',
    description:'Centralized Auth App brought all the third-party applications to one platform, If the user ' +
    'has access to the following third-party apps, he/she would be able to access the application from CA. ' +
    'The user would be able to navigate to the login screen (if not already logged-in) of the required ' +
    'third-party application. These third-party apps are KOBO Dashboard, Power BI, MIX Panel, and Turn IO.',
    options: []
  }
]

export default helpMenu
