import React, { Fragment, useState, useEffect } from 'react'
import 'styles/User.form.styles.scss'
import 'styles/Main.body.styles.scss'
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import SideNavBar from 'components/layout/SideNavBar'
import { updateUser, createUser, getUser, resetSaveStatus } from 'actions/user.action'
import { connect } from 'react-redux'
import {
  checkErrors,
  countFormData,
} from 'components/util/validation/form.validation'
import {
  countries,
  userRoles,
  appPermission
} from 'components/util/static/index.static'
import { loadVaccineOrganizationUsers } from 'actions/user.action'
import { getInsurers, getSuppliers } from 'actions/logistics-vendors.action'
import { getOrganizationPartners } from 'actions/organization-partner.action'
import {AiOutlineLoading3Quarters} from 'react-icons/ai'
import {
  TextField,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Button,
  RadioGroup,
  Radio,
  Autocomplete,
  FormControlLabel,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ReactGroupSelect from 'react-select'
import Loader from '../util/Loader'

const initialState = {
  firstname: '',
  lastname: '',
  username: '',
  password: '',
  email: '',
  country: 'all',
  organizationId: '',
  supplier: [],
  isSupplierRoleAdmin: 'true',
  isInsurerRoleAdmin: 'true',
  insurer: [],
  organization: [],
  permission: [],
  userRole: [],
}
const fieldErrors = {
  firstname: '',
  lastname: '',
  username: '',
  password: '',
  email: '',
  country: '',
  supplier: [],
  isSupplierRoleAdmin: '',
  isInsurerRoleAdmin: '',
  insurer: [],
  organization: [],
  organizationId: '',
  permission: [],
  userRole: [],
  empty: false,
}

const distinctVaccineRoles = [
  'vaccine-provider',
  'vaccine-campaign-admin',
  'vaccine-organisation',
  'vaccine-admin',
]

const vaccineOrganizatonRoles = [
  {
    label: 'reach52 Users',
    appId: '',
    options: [
      {
        label: 'App User',
        value: 'app-user',
      },
    ],
  },
  {
    label: 'Insights Vaccination',
    appId: 'insights',
    options: [
      {
        label: 'Vaccine Campaigner',
        value: 'vaccine-campaign-admin',
      },
      {
        label: 'Vaccine Inventory',
        value: 'vaccine-provider',
      }
    ]
  }
]

const UserForm = ({
  createUser,
  updateUser,
  getUser,
  suppliers,
  insurers,
  user: { selectedUser, vaccineOrganizationUsers, loading, saving },
  loadVaccineOrganizationUsers,
  getInsurers,
  getSuppliers,
  getOrganizationPartners,
  organizationPartners,
  userInfo,
  resetSaveStatus
}) => {
  const [isVaccineOrganization] = useState(
    !userInfo.userRole.includes('super-admin') &&
    userInfo.userRole.includes('vaccine-organisation')
  )
  const [userFormData, setUserFormData] = useState({
    ...initialState, 
    country: isVaccineOrganization
      ? 'ind'
      : 'all',
    organizationId: isVaccineOrganization
      ? userInfo.id
      : '',
    permission: isVaccineOrganization
      ? ['insights']
      : []
  })
  const [userErrorField, setUserErrorField] = useState(fieldErrors)
  const [selectedUserRoles, setSelectedUserRole] = useState([])

  const { userId } = useParams()
  const creatingUser = useMatch('/users/register')
  const navigate = useNavigate()

  useEffect(() => {
    loadVaccineOrganizationUsers()
    if (creatingUser) {
      getInsurers('all')
      getSuppliers('all')
      getOrganizationPartners('EP', 'all')
    } else {
      getInsurers(selectedUser['country'].iso3)
      getSuppliers(selectedUser['country'].iso3)
      getOrganizationPartners('EP', selectedUser['country'].iso3)
    }
  }, [loadVaccineOrganizationUsers, getInsurers, getSuppliers, getOrganizationPartners, selectedUser])

  useEffect(() => {
    if (selectedUser.username === '' && userId) {
      getUser(userId)
    }
    if (selectedUser && userId) {
      const userProfile = { ...initialState }
      for (const key in selectedUser) {
        if (key in userProfile) userProfile[key] = selectedUser[key]
      }
      for (const key in selectedUser.credential) {
        if (key in userProfile && key !== 'organization') {
          userProfile[key] = selectedUser.credential[key]
        }
      }
      userProfile['country'] = selectedUser['country'].iso3

      if (selectedUser.credential) {
        if (selectedUser.credential.organization.length > 0) {
          userProfile.organization = []
          userProfile.organization = selectedUser.credential.organization.map(
            (obj) => obj.code
          )
        }
        if (selectedUser.credential.associate.length > 0) {
          userProfile.insurer = []
          userProfile.supplier = []
          selectedUser.credential.associate.forEach((obj) => {
            if (obj.type === 'insurer') {
              let data = insurers.filter((val) => val.code === obj.code)[0]
              userProfile.insurer.push(data)
              userProfile.isInsurerRoleAdmin =
                obj.role === 'admin' ? 'true' : 'false'
            }
            if (obj.type === 'supplier') {
              let data = suppliers.filter(
                (val) => val.supplierCode === obj.code
              )[0]
              userProfile.supplier.push(data)
              userProfile.isSupplierRoleAdmin =
                obj.role === 'admin' ? 'true' : 'false'
            }
          })
        }
      }
      let userRoleMapping = []
      userRoleMapping = userRoles.reduce(
        (prevValue, roles) => [
          ...prevValue,
          ...roles.options.filter((role) =>
            userProfile.userRole.includes(role.value)
          ),
        ],
        []
      )
      userRoleMapping = isVaccineOrganization
        ? userRoleMapping.filter((val)=> ['app-user', "vaccine-provider", "vaccine-campaign-admin"].includes(val.value))
        : userRoleMapping
      setSelectedUserRole(userRoleMapping)
      userProfile['userRole'] = userRoleMapping.map((val) => val.value)
      if (isVaccineOrganization) {
        userProfile.organizationId = isVaccineOrganization
          ? userInfo.id
          : ''
        userProfile.permission = isVaccineOrganization
          ? ['insights']
          : selectedUser.permission
        userProfile.userRole = isVaccineOrganization
          ? userProfile.userRole.filter((value)=>['app-user', "vaccine-provider", "vaccine-campaign-admin"].includes(value))
          : selectedUser.userRole
        userProfile.country = isVaccineOrganization
          ? selectedUser['country'].iso3 === 'all'
            ? 'ind'
            : selectedUser['country'].iso3
          : 'all'
      }

      setUserFormData(userProfile)
    }
  }, [
    selectedUser,
    vaccineOrganizationUsers,
    getUser,
    userId,
    isVaccineOrganization,
    userInfo
  ])

  const {
    firstname,
    lastname,
    username,
    password,
    email,
    country,
    organizationId,
    supplier,
    isSupplierRoleAdmin,
    isInsurerRoleAdmin,
    insurer,
    organization,
    permission,
  } = userFormData

  const userRoleFieldChange = (evt) => {
    const name = 'userRole'
    let value = checkDeletedUserRoles(evt, userFormData, setUserFormData)
    checkErrors({ name, value }, {}, userErrorField, setUserErrorField)
    setUserFormData({
      ...userFormData,
      [name]: value,
    })
  }
  const checkDeletedUserRoles = (evt, formData, setUserFormData) => {
    let value = evt.map((val) => val.value)

    let data = evt.filter((val) => distinctVaccineRoles.includes(val.value))

    let userRoleVaccine = formData.userRole.filter((val) =>
      distinctVaccineRoles.includes(val)
    )

    //select distinct vaccine role from multiple vaccine userRole
    if (userRoleVaccine.length > 1) {
      value = formData.userRole.filter(
        (elem) => !userRoleVaccine.slice(1).includes(elem)
      )
      evt = evt.filter((item) => !userRoleVaccine.slice(1).includes(item.value))
      data = evt.filter((val) => distinctVaccineRoles.includes(val.value))
    }

    //for only one selection at change event vaccine
    if (userRoleVaccine.length > 0 && data.length > 1) {
      value.splice(value.indexOf(userRoleVaccine[0]), 1)
      evt = evt.filter((item) => item.value !== userRoleVaccine[0])
    }
    setSelectedUserRole(evt)
    if (
      !(
        value.includes('vaccine-provider') ||
        value.includes('vaccine-campaign-admin')
      )
    ) {
      delete formData.organizationId
    } else {
      formData.organizationId = formData.organizationId
        ? formData.organizationId
        : isVaccineOrganization
        ? userInfo.id
        : ''
    }
    setUserFormData(formData)
    return value
  }
  const checkDeletedFields = (
    value,
    formData,
    setUserFormData,
    errorField,
    setUserErrorField
  ) => {
    if (value.includes('logistics-pharma')) {
      formData.supplier = formData.supplier ? formData.supplier : []
      formData.isSupplierRoleAdmin = formData.isSupplierRoleAdmin
        ? formData.isSupplierRoleAdmin
        : 'true'
      errorField.supplier = errorField.supplier ? errorField.supplier : ''
    } else {
      formData.isSupplierRoleAdmin = 'true'
      formData.supplier = []
      errorField.supplier = ''
    }
    if (value.includes('logistics-insurance')) {
      formData.isInsurerRoleAdmin = formData.isInsurerRoleAdmin
        ? formData.isInsurerRoleAdmin
        : 'true'
      formData.insurer = formData.insurer ? formData.insurer : []
      errorField.insurer = errorField.insurer ? errorField.insurer : ''
    } else {
      formData.isInsurerRoleAdmin = 'true'
      formData.insurer = []
      errorField.insurer = ''
    }
    if (value.includes('access-web')) {
      formData.organization = formData.organization ? formData.organization : []
      errorField.organization = errorField.organization
        ? errorField.organization
        : ''
    } else {
      formData.organization = []
      errorField.organization = ''
    }
    setUserFormData(formData)
    setUserErrorField(errorField)
  }
  const onChange = (evt) => {
    if (!(evt.target.name === 'supplier' || evt.target.name === 'insurer')) {
      evt.preventDefault()
    }
    const { name, value } = evt.target
    if (name === 'permission') {
      checkDeletedFields(
        value,
        userFormData,
        setUserFormData,
        userErrorField,
        setUserErrorField
      )
    }
    checkErrors(evt.target, {}, userErrorField, setUserErrorField)
    if (name === 'country') {
      getInsurers(value)
      getSuppliers(value)
      getOrganizationPartners('EP', value)
      const data = {
        ...userFormData,
        'supplier': [],
        'insurer': [],
        'organization': [],
        [name]: value
      }
      setUserFormData(data)
    } else {
      setUserFormData({
        ...userFormData,
        [name]: value,
      })
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()

    // get country object
    let countryObject = countries.find(
      (ctr) => ctr.iso3 === userFormData.country
    )

    // delete password for update function
    if (!creatingUser) delete userFormData.password

    // delete supplier, insurer and organizations if required app is not selected
    if (!permission.includes('logistics-pharma')) {
      delete userFormData.supplier
      delete userFormData.isSupplierRoleAdmin
      userErrorField.supplier = ''
    }
    if (!permission.includes('logistics-insurance')) {
      delete userFormData.isInsurerRoleAdmin
      delete userFormData.insurer
      userErrorField.insurer = ''
    }
    if (!permission.includes('access-web')) {
      delete userFormData.organization
      userErrorField.organization = ''
    }
    if (
      !(
        userFormData.userRole.includes('vaccine-provider') ||
        userFormData.userRole.includes('vaccine-campaign-admin')
      )
    ) {
      delete userFormData.organizationId
    } else {
      userFormData.organizationId = userFormData.organizationId
        ? userFormData.organizationId
        : isVaccineOrganization
        ? userInfo.id
        : ''
    }

    // fill up all the fields
    if (!(countFormData(userFormData) === Object.keys(userFormData).length)) {
      setUserErrorField({
        ...userErrorField,
        empty: true,
      })
      setTimeout(() => {
        setUserErrorField({
          ...userErrorField,
          empty: false,
        })
      }, 2000)
    }
    if (
      countFormData(userFormData) === Object.keys(userFormData).length &&
      countFormData(userErrorField) === 0
    ) {
      let userProfile = {
        ...userFormData,
        country: countryObject,
      }
      if (
        !(
          userFormData.userRole.includes('vaccine-provider') ||
          userFormData.userRole.includes('vaccine-campaign-admin')
        )
      ) {
        userProfile.organizationId = ''
      }
      let organizationData = []
      if (permission.includes('access-web') && userProfile.organization) {
        organizationData = organizationPartners.filter((obj) =>
          userProfile.organization.includes(obj.code)
        )
      }

      let associate = []
      if (permission.includes('logistics-pharma') && userProfile.supplier) {
        suppliers.forEach((obj) => {
          let checkForSupplierCode = userProfile.supplier.some(
            (val) => obj.supplierCode === val.supplierCode
          )
          let associateObj = {}
          if (checkForSupplierCode) {
            associateObj.type = 'supplier'
            associateObj.name = obj.supplierName.eng
            associateObj.code = obj.supplierCode
            associateObj.role =
              isSupplierRoleAdmin === 'true' ? 'admin' : 'underwriter'
            associate.push(associateObj)
          }
        })
      }

      if (permission.includes('logistics-insurance') && userProfile.insurer) {
        insurers.forEach((obj) => {
          let checkForInsurerCode = userProfile.insurer.some(
            (val) => obj.code === val.code
          )
          let associateObj = {}
          if (checkForInsurerCode) {
            associateObj.type = 'insurer'
            associateObj.name = obj.name.ENG
            associateObj.code = obj.code
            associateObj.role =
              isInsurerRoleAdmin === 'true' ? 'admin' : 'underwriter'
            associate.push(associateObj)
          }
        })
      }
      userProfile.associate = associate
      userProfile.organization = organizationData
      creatingUser
        ? createUser(userProfile, navigate, false)
        : updateUser(userProfile, userId, navigate, '/users')
    }
  }

  const formatGroupLabel = (data) => (
    <div className='group-styles'>
      <span>{data.label}</span>
      <span className='group-badge-styles'>{data.options.length}</span>
    </div>
  )

  const roleOptionsDisabled = (option)=>{
   return (option.value === 'super-admin' &&
      selectedUserRoles.find(
        (role) => role.value === 'app-user'
      )) ||
      (option.value === 'app-user' &&
      selectedUserRoles.find(
        (role) => role.value === 'super-admin'
      )) ||
      (option.value === 'super-admin' &&
      selectedUserRoles.find(
        (role) => role.value === 'vaccine-organisation'
      )) ||
      (option.value === 'vaccine-organisation' &&
      selectedUserRoles.find(
        (role) => role.value === 'super-admin'
      ))
  }

  useEffect(() => {
    resetSaveStatus()
  }, [])

  return (
    <Fragment>
      <SideNavBar />
      <div className='body-container'>
        {loading && 
          <Loader show={loading} message="Getting user information"/>
        }
        {!loading && (
        <Fragment>
          {/* Start of code line */}
          <h1 className='body-header-text'>
            {creatingUser ? 'Create ' : 'Update '} User
          </h1>

          <form className='user-form' onSubmit={onSubmit}>
            <div className='form-field'>
              <label className='form-label'>First Name</label>
              <input
                disabled={!creatingUser}
                className='input-default'
                name='firstname'
                type='text'
                placeholder='Enter first name'
                value={firstname}
                onChange={onChange}
              />
              {userErrorField.firstname && (
                <label className='validation-error'>
                  {userErrorField.firstname}
                </label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Last Name</label>
              <input
                disabled={!creatingUser}
                className='input-default'
                name='lastname'
                type='text'
                placeholder='Enter last name'
                value={lastname}
                onChange={onChange}
              />
              {userErrorField.lastname && (
                <label className='validation-error'>
                  {userErrorField.lastname}
                </label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Username</label>
              <input
                disabled={!creatingUser}
                name='username'
                type='text'
                autoComplete='none'
                placeholder='Enter username'
                value={username}
                onChange={onChange}
              />
              {userErrorField.username && (
                <label className='validation-error'>
                  {userErrorField.username}
                </label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Email</label>
              <input
                disabled={!creatingUser}
                name='email'
                type='text'
                placeholder='Enter email Id'
                value={email}
                onChange={onChange}
              />
              {userErrorField.email && (
                <label className='validation-error'>{userErrorField.email}</label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Country</label>
              <TextField
                id='country'
                select
                name='country'
                value={country}
                onChange={onChange}
              >
                {(isVaccineOrganization ? countries.slice(1) : countries).map((option) => (
                  <MenuItem key={option.iso3} value={option.iso3}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              {userErrorField.country && (
                <label className='validation-error'>
                  {userErrorField.country}
                </label>
              )}
            </div>

            {creatingUser ? (
              <div className='form-field'>
                <label className='form-label'>Password</label>
                <input
                  name='password'
                  type='password'
                  autoComplete='new-password'
                  placeholder='Enter password'
                  value={password}
                  onChange={onChange}
                />
                {userErrorField.password && (
                  <label className='validation-error'>
                    {userErrorField.password}
                  </label>
                )}
              </div>
            ) : (
              ''
            )}

            <div className='form-field'>
              <label className='form-label'>User Role</label>
              <ReactGroupSelect
                isMulti
                options={
                  isVaccineOrganization
                  ? vaccineOrganizatonRoles
                  : userRoles.map((role)=>{
                    role.options.forEach((option, index)=>{
                      if(option.value === 'vaccine-provider'|| option.value === 'vaccine-campaign-admin') delete role.options[index]     
                      if(option.value === 'insights-campaign') delete role.options[index]        
                    })
                    return role
                  })
                }
                formatGroupLabel={formatGroupLabel}
                value={selectedUserRoles}
                name='userRole'
                isOptionDisabled={(option) =>
                  roleOptionsDisabled(option)
                }
                onChange={userRoleFieldChange}
              />
              {userErrorField.userRole && (
                <label className='validation-error'>
                  {userErrorField.userRole}
                </label>
              )}
            </div>

            {userFormData.userRole.includes('vaccine-provider') ||
            userFormData.userRole.includes('vaccine-campaign-admin') ? (
              <div className='form-field'>
                <label className='form-label'>Vaccine Organization</label>
                <TextField
                  id='organizationId'
                  select
                  disabled={isVaccineOrganization}
                  name='organizationId'
                  className='capitalize'
                  value={organizationId}
                  onChange={onChange}
                >
                  {vaccineOrganizationUsers.map((option) => (
                    <MenuItem
                      className='capitalize'
                      key={option.profile._id}
                      value={option.profile._id}
                    >
                      {option.username}
                    </MenuItem>
                  ))}
                </TextField>
                {userErrorField.organizationId && (
                  <label className='validation-error'>
                    {userErrorField.organizationId}
                  </label>
                )}
              </div>
            ) : (
              ''
            )}

            <div className='form-field'>
              <label className='form-label'>App Permission</label>
              <FormControl sx={{ m: 1 }}>
                <Select
                  labelId='app-permission-checkbox-label'
                  id='app-permission-checkbox'
                  multiple
                  disabled={isVaccineOrganization}
                  value={permission}
                  name='permission'
                  onChange={onChange}
                  renderValue={(selected) => {
                    let selectedItems = []
                    appPermission.forEach((obj) => {
                      if (selected.includes(obj.value)) {
                        selectedItems.push(obj.label)
                      }
                    })
                    return selectedItems.join(', ')
                  }}
                >
                  {appPermission.map((value) => (
                    <MenuItem key={value.value} value={value.value}>
                      <Checkbox
                        checked={permission.indexOf(value.value) !== -1}
                      />
                      <ListItemText primary={value.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {userErrorField.permission && (
                <label className='validation-error'>
                  {userErrorField.permission}
                </label>
              )}
            </div>

            {permission.includes('logistics-pharma') ? (
              <>
                <div className='form-field'>
                  <label className='form-label'>Supplier</label>
                  <FormControl sx={{ m: 1 }}>
                    <Autocomplete
                      id='supplier'
                      multiple
                      value={supplier}
                      name='supplier'
                      onChange={(e, newVal) => {
                        const code = newVal.some((val) => val?.supplierCode === '*')
                        let newSuppliers = newVal
                        if (code) {
                          newSuppliers = newVal.filter((val) => val?.supplierCode === '*')
                        }
                        const target = { name: 'supplier', value: newSuppliers }
                        onChange({ target })
                      }}
                      limitTags={1}
                      options={suppliers}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option?.supplierName?.eng}
                      isOptionEqualToValue={(option, value) =>
                        option?.supplierCode === value?.supplierCode
                      }
                      getOptionDisabled={(option) =>
                        option?.supplierCode !== '*' &&
                        supplier.some((val) => val?.supplierCode === '*')
                      }
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props}>
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                              checkedIcon={<CheckBoxIcon fontSize='small' />}
                              style={{ marginRight: 8 }}
                              checked={
                                selected ||
                                supplier.some((val) => val?.supplierCode === '*')
                              }
                            />
                            {option?.supplierName?.eng}
                          </li>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder='' />
                      )}
                    />
                  </FormControl>
                  {userErrorField.supplier && (
                    <label className='validation-error'>
                      {userErrorField.supplier}
                    </label>
                  )}
                </div>

                <div className='form-field'>
                  <label className='form-label'>Supplier Role</label>
                  <RadioGroup
                    className='radio-group'
                    value={isSupplierRoleAdmin}
                    onChange={onChange}
                    name='isSupplierRoleAdmin'
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Underwriter'
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Admin'
                    />
                  </RadioGroup>
                </div>
              </>
            ) : (
              ''
            )}

            {permission.includes('logistics-insurance') ? (
              <>
                <div className='form-field'>
                  <label className='form-label'>Insurer</label>
                  <FormControl sx={{ m: 1 }}>
                    <Autocomplete
                      id='insurer'
                      multiple
                      value={insurer}
                      name='insurer'
                      onChange={(e, newVal) => {
                        const isCodePresent = newVal.some((val) => val?.code === '*')
                        let newInsurers = newVal
                        if (isCodePresent) {
                          newInsurers = newVal.filter((val) => val?.code === '*')
                        }
                        const target = { name: 'insurer', value: newInsurers }
                        onChange({ target })
                      }}
                      limitTags={1}
                      options={insurers}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option?.name?.ENG}
                      isOptionEqualToValue={(option, value) =>
                        option?.code === value?.code
                      }
                      getOptionDisabled={(option) =>
                        option?.code !== '*' &&
                        insurer.some((val) => val?.code === '*')
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                            checkedIcon={<CheckBoxIcon fontSize='small' />}
                            style={{ marginRight: 8 }}
                            checked={
                              selected || insurer.some((val) => val?.code === '*')
                            }
                          />
                          {option?.name?.ENG}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} placeholder='' />
                      )}
                    />
                  </FormControl>
                  {userErrorField.insurer && (
                    <label className='validation-error'>
                      {userErrorField.insurer}
                    </label>
                  )}
                </div>

                <div className='form-field'>
                  <label className='form-label'>Insurer Role</label>
                  <RadioGroup
                    className='radio-group'
                    value={isInsurerRoleAdmin}
                    onChange={onChange}
                    name='isInsurerRoleAdmin'
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Underwriter'
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Admin'
                    />
                  </RadioGroup>
                </div>
              </>
            ) : (
              ''
            )}

            {permission.includes('access-web') ? (
              <div className='form-field'>
                <label className='form-label'>Event Organization list</label>
                <FormControl sx={{ m: 1 }}>
                  <Select
                    labelId='app-organization-checkbox-label'
                    id='app-organization-checkbox'
                    multiple
                    value={organization}
                    name='organization'
                    onChange={onChange}
                    renderValue={(selected) => {
                      let selectedItems = []
                      organizationPartners.forEach((obj) => {
                        if (selected.includes(obj.code)) {
                          selectedItems.push(obj.name)
                        }
                      })
                      return selectedItems.join(', ')
                    }}
                  >
                    {organizationPartners.map((value) => (
                      <MenuItem key={value.code} value={value.code}>
                        <Checkbox
                          checked={organization.indexOf(value.code) !== -1}
                        />
                        <ListItemText primary={value.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {userErrorField.organization && (
                  <label className='validation-error'>
                    {userErrorField.organization}
                  </label>
                )}
              </div>
            ) : (
              ''
            )}

            <span className='form-field'>
              {userErrorField.empty && (
                <label className='validation-error'>
                  Please fill up all the fields
                </label>
              )}
            </span>
            <Button
              disabled={saving}
              onClick={onSubmit}
              variant='contained'
              className='submit-button'
              
            >
              {
                saving
                ? <AiOutlineLoading3Quarters size={24} color='white' className='animate-spin'/>
                : (creatingUser ? 'Create ' : 'Update') + ' User'
              }
            </Button>
          </form>
        {/* End of code line */}
        </Fragment> )} 
      </div>
    </Fragment>
  )
}

UserForm.propTypes = {
  updateUser: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  insurers: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  loadVaccineOrganizationUsers: PropTypes.func.isRequired,
  getInsurers: PropTypes.func.isRequired,
  getSuppliers: PropTypes.func.isRequired,
  getOrganizationPartners: PropTypes.func.isRequired,
  organizationPartners: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user,
  suppliers: state.logisticsVendors.suppliers,
  insurers: state.logisticsVendors.insurers,
  organizationPartners: state.organizationPartners.organizationPartners,
  userInfo: state.login.user,
})

export default connect(mapStateToProps, {
  updateUser,
  createUser,
  getUser,
  getInsurers,
  getSuppliers,
  loadVaccineOrganizationUsers,
  getOrganizationPartners,
  resetSaveStatus
})(UserForm)
