import emailValidation from './email.validation'

// set field errors
const setErrors = (
  name,
  message = 'error field',
  { setUserErrorField, userErrorField }
) => {
  setUserErrorField({
    ...userErrorField,
    [name]: message,
  })
}

// check field errors
export const checkErrors = (
  { name, value },
  passwordFormData,
  userErrorField,
  setUserErrorField
) => {
  const errorsState = { setUserErrorField, userErrorField }
  switch (name) {
    case 'firstname':
      value.length < 2
        ? setErrors(
            name,
            'firstname must be at least 2 characters',
            errorsState
          )
        : true && value.length > 128
        ? setErrors(
            name,
            'firstname must be at maximum 128 characters',
            errorsState
          )
        : setErrors(name, '', errorsState)
      break
    case 'lastname':
      value.length < 2
        ? setErrors(name, 'lastname must be at least 2 characters', errorsState)
        : true && value.length > 128
        ? setErrors(
            name,
            'lastname must be at maximum 128 characters',
            errorsState
          )
        : setErrors(name, '', errorsState)
      break
    case 'username':
      value.length < 2
        ? setErrors(name, 'username must be at least 2 characters', errorsState)
        : true && value.length > 32
        ? setErrors(
            name,
            'username must be at maximum 32 characters',
            errorsState
          )
        : setErrors(name, '', errorsState)
      break
    case 'password':
    case 'currentPassword':
      var tempErrorState = {...userErrorField};
      if(value.length < 8){
        tempErrorState[name] = 'password must be at least 8 characters'
      } else if(value.length > 32){
        tempErrorState[name] = 'Password must be at maximum 32 characters'
      } else {
        tempErrorState[name] = ''
      }
      
      if (passwordFormData.confirmPassword) {
        if(value !== passwordFormData.confirmPassword){
          tempErrorState.confirmPassword = 'Passwords do not match'
        } else {
          tempErrorState.confirmPassword = ''
        }
      }

      setUserErrorField({...userErrorField, ...tempErrorState})

      break
    case 'confirmPassword':
      value !== passwordFormData.password
        ? setErrors(name, 'Password does not match', errorsState)
        : setErrors(name, '', errorsState)
      break
    case 'email':
      value.length < 2
        ? setErrors(name, 'email must be at least 2 characters', errorsState)
        : true && value.length > 128
        ? setErrors(
            name,
            'email must be at maximum 128 characters',
            errorsState
          )
        : setErrors(name, '', errorsState) || !value.match(emailValidation)
        ? setErrors(name, 'email must be valid', errorsState)
        : setErrors(name, '', errorsState)
      break
    case 'country':
      value.length === 0
        ? setErrors(name, '', 'country is required', errorsState)
        : setErrors(name, '', errorsState)
      break
    case 'permission':
      value.length === 0
        ? setErrors(name, 'permission is required', errorsState)
        : setErrors(name, '', errorsState)
      break
    case 'supplier':
      value.length === 0
        ? setErrors(name, 'supplier is required', errorsState)
        : setErrors(name, '', errorsState)
      break
    case 'insurer':
      value.length === 0
        ? setErrors(name, 'insurer is required', errorsState)
        : setErrors(name, '', errorsState)
      break
    case 'organization':
      value.length === 0
        ? setErrors(name, 'Organization is required', errorsState)
        : setErrors(name, '', errorsState)
      break
    case 'userRole':
      value.length === 0
        ? setErrors(name, 'user role is required', errorsState)
        : setErrors(name, '', errorsState)
      break
    case 'organizationId':
      value.length === 0
        ? setErrors(name, 'vaccine organization is required', errorsState)
        : setErrors(name, '', errorsState)
      break
    case 'campaign':
      value.length === 0
        ? setErrors(name, 'Campaign is required', errorsState)
        : setErrors(name, '', errorsState)
      break
    case 'contactNo':
      value.length === 0
        ? setErrors(name, 'Contact Number is required', errorsState)
        : setErrors(name, '', errorsState)
      break
    case 'profilePic':
      value.length === 0
        ? setErrors(name, 'ProfilePic is required', errorsState)
        : setErrors(name, '', errorsState)
      break
    default:
      break
  }
}

export const countFormData = (fields) => {
  let count = 0
  Object.values(fields).forEach((item) => item.length > 0 && (count += 1))
  return count
}

// set field errors
const setErrorsForOrganizationForm = (
  name,
  message = 'error field',
  { setOrganizationErrorField, organizationErrorField }
) => {
  setOrganizationErrorField({
    ...organizationErrorField,
    [name]: message,
  })
}

export const checkErrorsForOrganizationForm = (
  { name, value },
  organizationErrorField,
  setOrganizationErrorField
) => {
  const errorsState = { setOrganizationErrorField, organizationErrorField }
  switch (name) {
    case 'organizationType':
      value===''
        ? setErrorsForOrganizationForm(
            name,
            'firstname must be at least 2 characters',
            errorsState
          )
        : setErrorsForOrganizationForm(name, '', errorsState)
      break
    case 'organizationName':
      value===''
        ? setErrorsForOrganizationForm(name, 'organizationName is required', errorsState)
        : setErrorsForOrganizationForm(name, '', errorsState)
      break
    case 'organizationCode':
        value===''
        ? setErrorsForOrganizationForm(name, 'organizationCode', errorsState)
        : setErrorsForOrganizationForm(name, '', errorsState)
      break
    case 'catalogTags':
        value.length === 0
        ? setErrorsForOrganizationForm(name, 'catalogTags is required', errorsState)
        : setErrorsForOrganizationForm(name, '', errorsState)
        break
    case 'deliveryFee':
      value===''
        ? setErrorsForOrganizationForm(name, '', 'deliveryFee is required', errorsState)
        : setErrorsForOrganizationForm(name, '', errorsState)
      break
    case 'authorizedPerson':
      value===''
        ? setErrorsForOrganizationForm(name, 'authorizedPerson is required', errorsState)
        : setErrorsForOrganizationForm(name, '', errorsState)
      break
    case 'phone':
        value.length === 0
        ? setErrorsForOrganizationForm(name, 'Phone is required', errorsState)
        : setErrorsForOrganizationForm(name, '', errorsState)
      break
    case 'email':
        value.length < 2
        ? setErrorsForOrganizationForm(name, 'email must be at least 2 characters', errorsState)
        : true && value.length > 128
        ? setErrorsForOrganizationForm(
            name,
            'email must be at maximum 128 characters',
            errorsState
          )
        : setErrorsForOrganizationForm(name, '', errorsState) || !value.match(emailValidation)
        ? setErrorsForOrganizationForm(name, 'email must be valid', errorsState)
        : setErrorsForOrganizationForm(name, '', errorsState)
      break
    case 'addresses':
      value.length === 0
        ? setErrorsForOrganizationForm(name, 'Address is required', errorsState)
        : setErrorsForOrganizationForm(name, '', errorsState)
      break
    case 'operatingCountries':
      value.length === 0
        ? setErrorsForOrganizationForm(name, 'operatingCountries is required', errorsState)
        : setErrorsForOrganizationForm(name, '', errorsState)
      break
    case 'status':
        value===''
        ? setErrorsForOrganizationForm(name, 'status is required', errorsState)
        : setErrorsForOrganizationForm(name, '', errorsState)
      break
    default:
      break
  }
}
