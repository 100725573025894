import { AT_SET_CAMPAIGNS } from '../actions/types.actions'

const initialState = {
  campaigns: [],
  campaignsLoading: true
}

const campaignsReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case AT_SET_CAMPAIGNS:
      return {
        ...state,
        campaigns: payload,
        campaignsLoading: false
      }
    default:
      return state
  }
}
export default campaignsReducer
