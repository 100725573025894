import 'styles/user.table.scss'
import React, { useState } from 'react'
import userRolesFlattened from 'components/util/static/userRolesFlattened.static'

const UserRoleBlock = ({ role }) => {

    const [roleDetails] = useState(
        userRolesFlattened.find(it => it.value === role)
    )
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <div
            className='role-wrapper'
            style={{
                backgroundColor: roleDetails?.color || '#000'
            }}
            onClick={() => setIsExpanded(!isExpanded)}
            title={`${roleDetails?.category || role}: ${roleDetails?.label}`}
        >
            { ( roleDetails
                ? (
                    isExpanded
                    ? `${roleDetails.category}: ${roleDetails.label}`
                    : roleDetails.short_code
                )
                : false )  || role}
        </div>
    )
}

export default UserRoleBlock
