import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import helpMenu from 'components/util/static/helpMenu.static'
import 'styles/Help.styles.scss'

export const HelpMenu = () => {
  const [expanded, setExpanded] = useState(helpMenu[0].panelId)
  const [expandedInnerPanel, setExpandedInnerPanel] = useState()
  const [expandedInner1Panel, setExpandedInner1Panel] = useState()

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  const handleChangeInnerPanel = (panel) => (event, newExpanded) => {
    setExpandedInnerPanel(newExpanded ? panel : false)
  }
  const handleChangeInner1Panel = (panel) => (event, newExpanded) => {
    setExpandedInner1Panel(newExpanded ? panel : false)
  }
  return (
    <div className='body-container'>
      <h1 className='body-header-text'>Help</h1>
      <div className='main-container-body'>
        {helpMenu.map((app, index) => {
          return (
            <Accordion
              key={'panel' + app.panelId}
              name='panel'
              expanded={expanded === app.panelId}
              onChange={handleChange(app.panelId)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id={'header' + app.panelId}
              >
                <Typography>{app.label}</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography className='header-description' >{app.description}</Typography>
                {app.options.map((modules) => {
                  const updatedDescription = modules.description.split("\n").map((value, index) => {
                    return (
                      <span key={index}>
                        { index !== 0 && modules.value === 'super-admin' ?
                          <>&ensp; &ensp; &ensp; &ensp;</>
                          : <></>}
                        {value}
                        <br />
                      </span>
                    )
                  })
                  return (
                    <Accordion
                      name='ipanel'
                      key={'ipanel' + modules.value}
                      expanded={expandedInnerPanel === modules.value}
                      onChange={handleChangeInnerPanel(modules.value)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1a-content'
                        id={'iheader' + modules.value}
                      >
                        <Typography className='header-title'>{modules.label}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className='header-description'>{updatedDescription}</Typography>
                        {modules.options && modules.options.length > 0 ? 
                          modules.options.map((roles) => {
                            return (
                              <Accordion
                                name='ipanel'
                                key={'ipanel' + roles.value}
                                expanded={expandedInner1Panel === roles.value}
                                onChange={handleChangeInner1Panel(roles.value)}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls='panel1a-content'
                                  id={'iheader' + roles.value}
                                >
                                  <Typography className='header-title'>{roles.label}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography className='header-description'>{roles.description}</Typography>
                                </AccordionDetails>
                              </Accordion>
                            )
                          })
                          : <></>
                        }
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}
