import api from '../lib/api.lib'

export const startSession = (appId, url, id, newWindow = true) => (dispatch) => {
  let session = localStorage.getItem('session')
    ? JSON.parse(localStorage.getItem('session'))
    : {
        logistics: {
          appId: '',
          isActive: false,
        },
        insights: {
          appId: '',
          isActive: false,
        },
        accessWeb: {
          appId: '',
          isActive: false,
        },
        asm: {
          appId: '',
          isActive: false,
        },
        connect: {
          appId: '',
          isActive: false,
        },
        userId: '',
        token: '',
      }

  try {
    switch (appId) {
      case 'logistics-pharma':
        session = {
          ...session,
          logistics: { appId, isActive: true },
        }
        break
      case 'logistics-insurance':
        session = {
          ...session,
          logistics: { appId, isActive: true },
        }
        break
      case 'connect':
        session = {
          ...session,
          connect: { appId: 'connect', isActive: true },
        }
        break
      case 'access-web':
        session = {
          ...session,
          accessWeb: { appId, isActive: true },
        }
        break
      case 'asm':
        session = {
          ...session,
          asm: { appId, isActive: true },
        }
        break
      case 'insights':
        session = {
          ...session,
          insights: { appId: 'insights', isActive: true }
        }
        break
      case 'kobodashboard':
      case 'bilogin':
      case 'mixpanel':
      case 'turnio':
        break
      default:
        break
    }
    session = { ...session, token: localStorage.getItem('token'), userId: id }
    localStorage.setItem('session', JSON.stringify(session))
    if(newWindow){
      window.open(url)
    } else {
      window.location.href = url
    }
  } catch (error) {
    console.log(error)
  }
}

export const validateSession = () => async (dispatch) => {
  await api.get(`/session/`)
}

export const closeSession = () => (dispatch) => {
  console.log('Session Closed')
  try {
    localStorage.removeItem('session')
  } catch (error) {
    console.log(error)
  }
}
