const countries = [
  { iso3: 'all', name: 'All' },
  { iso3: 'ind', name: 'India' },
  { iso3: 'idn', name: 'Indonesia' },
  { iso3: 'phl', name: 'Philippines' },
  { iso3: 'khm', name: 'Cambodia' },
  { iso3: 'ken', name: 'Kenya' },
  { iso3: 'zaf', name: 'South Africa' }
]

export default countries
