import React from 'react'
import { css } from '@emotion/react'
import { BounceLoader } from 'react-spinners'

const override = css`
display: block;
margin: 0 auto;
border-color: red;
`;

const loadingScreen = {
    textAlign: 'center',
    color: '#ab218f',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}

const Loader = ({show=false, message="Fetching data"}) => {
  return (
    <div style={loadingScreen}>
        <BounceLoader color="#ab218f" loading={show} css={override} size={135} speedMultiplier={0.8}/>
        <h3>{message}. Please wait</h3>
    </div>
  )
}

export default Loader