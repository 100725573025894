import { AT_SET_ORGANIZATION_PARTNERTS, ORGANIZATION_CREATED_SUCCESS , AT_SET_ORGANIZATION_PARTNERTS_LIST,
AT_SET_ORGANIZATION_PARTNER, AT_SET_ADDRESS_MODAL, AT_SET_REGION } from '../actions/types.actions'

const initialState = {
  organizationPartners: [],
  organizationPartnersLoading: true,
  organizationData: [],
  selectedOrganization: {},
  loading: true,
  isOpen: false,
  region: true
}

const organizationPartnersReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case AT_SET_ORGANIZATION_PARTNERTS:
      return {
        ...state,
        organizationPartners: payload,
        organizationPartnersLoading: false
      }
    case ORGANIZATION_CREATED_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      }
    case AT_SET_ORGANIZATION_PARTNERTS_LIST:
      return {
        ...state,
        organizationData: payload,
        loading: false
      }
    case AT_SET_ORGANIZATION_PARTNER:
      return {
        ...state,
        selectedOrganization: payload,
        loading: false
      }
    case AT_SET_ADDRESS_MODAL:
      return {
        ...state,
        isOpen: payload
      }
    case AT_SET_REGION:
      return {
        ...state,
        region: payload
      }
    default:
      return state
  }
}
export default organizationPartnersReducer
