import { AT_SET_ORGANIZATION_PARTNERTS, LOADING, ORGANIZATION_CREATED_SUCCESS, AT_SET_ADDRESS_LIST,
  AT_SET_ORGANIZATION_PARTNERTS_LIST, AT_SET_ORGANIZATION_PARTNER, AT_SET_NOTIFICATION,
  AT_SET_ADDRESS_MODAL, AT_SET_AVAILABLE_OPTIONS, AT_SET_NEW_ADDRESS, AT_SET_REGION } from './types.actions'
import api from '../lib/api.lib'

export const getOrganizationPartners = (organizationType, country) => async (dispatch) => {
  try {
      const region = country === 'idn' ? 'IDN' : 'IND'
      const data = {
        organizationType: organizationType,
        country: country,
        region: region
      }
    const res = await api.post('organization-partners/type', data)
    const result = res.data
    if(result){
      await dispatch({
        type: AT_SET_ORGANIZATION_PARTNERTS,
        payload: result,
      })
      await dispatch({
        type: LOADING,
        payload: false,
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const getOrganizations = ({ search, organizationTypes, countries, region }) => async (dispatch) => {
  try {
    const connectionServer = region ? 'IND' : 'IDN'
    const params = {
      organizationTypes: organizationTypes,
      filterCountries: countries,
      search: search,
      connectionServer: connectionServer
    }
    const res = await api.get('organization-partners', {params})
    const result = res.data
    if(result) {
      await dispatch({
        type: AT_SET_ORGANIZATION_PARTNERTS_LIST,
        payload: result.data.rows,
      })
      await dispatch({
        type: LOADING,
        payload: false,
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const getOrganization = (partnerId, region, navigate, isNavigateRequired) => async (dispatch) => {
  try {
    const connectionServer = region ? 'IND' : 'IDN'
    const res = await api.get(`organization-partners/${partnerId}?connectionServer=${connectionServer}`)
    const result = res.data
    if (result) {
      await dispatch({
        type: AT_SET_ORGANIZATION_PARTNER,
        payload: result.data
      })
      await dispatch({
        type: LOADING,
        payload: false
      })
      if(isNavigateRequired){
        navigate(`/organization-partners/edit/${partnerId}`)
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const createOrganization =
  (requestBody, navigate, region, timeout = 2000) =>
  async (dispatch) => {
    try {
      const connectionServer = region ? 'IND' : 'IDN'
      const res = await api.post(`organization-partners?connectionServer=${connectionServer}`, requestBody)

      await dispatch({
        type: LOADING,
        payload: true
      })
      if (res) {
        await dispatch({
          type: ORGANIZATION_CREATED_SUCCESS,
          payload: res.data.data,
        })
        await dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: true,
            className: 'SUCCESS',
            message: res.data.message,
          },
        })
        await dispatch({
          type: LOADING,
          payload: false,
        })
        navigate('/organization-partners')
      }
    } catch (error) {
      console.log(error)
      await dispatch({
        type: AT_SET_NOTIFICATION,
        payload: {
          is_open: true,
          className: 'FAILED',
          message: error.response.data.message
            ? 'Error: ' + error.response.data.message
            : 'Failed to get data',
        },
      })
    }
    setTimeout(
      () =>
      dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: false,
            className: '',
            message: '',
          },
        }),
      timeout
    )
  }


export const updateOrganization =
  (submittedData, partnerId, navigate, region, timeout = 2000) =>
  async (dispatch) => {
    try {
      const connectionServer = region ? 'IND' : 'IDN'
      const res = await api.post(`organization-partners/${partnerId}?connectionServer=${connectionServer}`, submittedData)
      await dispatch({
        type: LOADING,
        payload: true
      })
      if (res) {
        await dispatch({
          type: LOADING,
          payload: false,
        })
        await dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: true,
            className: 'SUCCESS',
            message: res.data.message,
          },
        })
        navigate('/organization-partners')
      }
    } catch (error) {
      console.log('error',error)
      await dispatch({
        type: AT_SET_NOTIFICATION,
        payload: {
          is_open: true,
          className: 'FAILED',
          message: error.response.data.message
            ? Array.isArray(error.response.data.message)
              ? 'Error: ' +
                error.response.data.message.map((val) => val.message)
              : error.response.data.message
            : 'Failed to get data',
        },
      })
    }
    setTimeout(
      () =>
        dispatch({
          type: AT_SET_NOTIFICATION,
          payload: {
            is_open: false,
            className: '',
            message: '',
          },
        }),
      timeout
    )
  }

export const getManyByLevel = ({ originLevel, level, connectionServer, includeEmptyChild, parentId }) => async (dispatch, getState) => {
  try {
    const region = connectionServer ? 'IND' : 'IDN'
    await dispatch({
      type: LOADING,
      payload: true
    })
    const res = await api.get(`address/level/${region}/${level}/${parentId}/${includeEmptyChild}`)
    const result = res.data
    if(result) {
      const availableOptions = getState().address.availableOptions
      availableOptions[originLevel] = result.map(val => ({ id: val._id, name: val.name.eng })).sort(addressComparator)
      await dispatch(setAvailableOptions(availableOptions))
      await dispatch({
        type: AT_SET_ADDRESS_LIST,
        payload: result,
      })
      await dispatch({
        type: LOADING,
        payload: false,
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const openAddresModal = (isOpen, objs) => async (dispatch) => {
  await dispatch({
    type: AT_SET_ADDRESS_MODAL,
    payload: isOpen
  })
  await dispatch({
    type: AT_SET_NEW_ADDRESS,
    payload: objs
  })
}

export const setAvailableOptions = (availableOptions) => async (dispatch) => {
  await dispatch({
    type: AT_SET_AVAILABLE_OPTIONS,
    payload: availableOptions,
  })
}

export const setRegion = (region) => async (dispatch) => {
  await dispatch({
    type: AT_SET_REGION,
    payload: region
  })
}

const addressComparator = (a, b) => {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}
