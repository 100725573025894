import {
  GET_USERS_LIST,
  GET_USER,
  USER_CREATED_SUCCESS,
  USER_DELETED_SUCCESS,
  AT_SET_VACCINE_ORGANIZATION_USERS,
  FILE_UPLOADED_SUCCESSFULLY,
  LOADING,
  SAVING
} from '../actions/types.actions'

const initialState = {
  userData: [],
  vaccineOrganizationUsers: [],
  loading: true,
  saving: false,
  selectedUser: {
    firstname: '',
    lastname: '',
    username: '',
    password: '',
    email: '',
    country: {
      name: '',
      iso3: '',
    },
    permission: [],
    userRole: [],
  },
  profilePicture: ''
}

const userReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_USERS_LIST:
      return {
        ...state,
        userData: payload,
        loading: false,
      }
    case GET_USER:
      return {
        ...state,
        selectedUser: payload,
        loading: false,
      }
    case USER_CREATED_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      }
    case USER_DELETED_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      }
    case AT_SET_VACCINE_ORGANIZATION_USERS:
      return {
        ...state,
        vaccineOrganizationUsers: payload,
        loading: false,
      }
    case FILE_UPLOADED_SUCCESSFULLY:
      return {
        ...state,
        profilePicture: payload
      }
    case LOADING:
      return {
        ...state,
        loading: payload
      }
    case SAVING:
      return {
        ...state,
        saving: payload
      }
    default:
      return state
  }
}

export default userReducer
