import axios from 'axios'
import { AT_SET_SUPPLIERS, AT_SET_INSURERS } from './types.actions'
import { LOGISTICS_API_BASE_URL } from '../lib/reach52AppsUrls'

const api = axios.create({
  baseURL: LOGISTICS_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const getInsurers = (country) => async (dispatch) => {
  try {
    const connectionServer = country === 'idn' ? 'IDN' : 'IND'
    const res = await api.get('api/insurers', {
      params: { connectionServer, country: [country] }
    })
    let result = []
    if (country === 'all') {
      result = result.concat({
        name: {
          ENG: 'All',
        },
        code: '*',
      })
    }
    result = result.concat(res.data.data)
    dispatch({
      type: AT_SET_INSURERS,
      payload: result,
    })
  } catch (error) {
    console.log(error)
  }
}

export const getSuppliers = (country) => async (dispatch) => {
  try {
    const connectionServer = country === 'idn' ? 'IDN' : 'IND'
    const res = await api.get('api/suppliers', {
      params: { connectionServer, country: [country] }
    })
    let result = []
    if (country === 'all') {
      result = result.concat({
        supplierName: {
          eng: 'All',
        },
        supplierCode: '*',
      })
    }
    result = result.concat(res.data.data)
    dispatch({
      type: AT_SET_SUPPLIERS,
      payload: result,
    })
  } catch (error) {
    console.log(error)
  }
}
