import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import React, { Fragment, useState } from 'react'
import UserTableList from './UserTableList'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { countries as countryList } from 'components/util/static/index.static'
import userRolesFlattened from 'components/util/static/userRolesFlattened.static'
import { debounce } from 'lodash'
const UserList = ({userInfo}) => {
  const [filters, setFilters] = useState({
    countries: [],
    roles: [],
    searchKey: '',
  })
  const { roles, countries, searchKey } = filters

  const isVaccineOrganization =
    !userInfo.userRole.includes('super-admin') &&
    userInfo.userRole.includes('vaccine-organisation')
  
  const isConnectSuperAdmin = userInfo.userRole.includes('insights-super-admin')

  const onSearchChange = debounce((evt) => {
    const { name, value } = evt.target
    setFilters({
      ...filters,
      [name]: value,
    })
  }, 500)

  const onFilterChange = (evt) => {
    evt.preventDefault()
    const { name, value } = evt.target
    setFilters({
      ...filters,
      [name]: value,
    })
  }
  return (
    <Fragment>
      <div className='body-container'>
        <h1 className='body-header-text'>Users</h1>
        <div className='body-header-actions '>
          <div className='left'>
            <Link className='custom-link' to='/users/register'>
              <Button
                variant='contained'
                onClick={() => {}}
                className='custom-button'
              >
                Add User
              </Button>
            </Link>
            {/* <FormControl variant='standard' sx={{ m: 1, width: 250 }}> */}
            <TextField
              sx={{ m: 1, width: 250 }}
              id='searchKey'
              label='Search'
              variant='standard'
              className='input'
              name='searchKey'
              onChange={onSearchChange}
            />
            {/* </FormControl> */}
          </div>
          <div className='right'>
            <FormControl variant='standard' sx={{ m: 1, width: 250 }}>
              <InputLabel id='demo-simple-select-autowidth-label'>
                Country
              </InputLabel>
              <Select
                labelId='app-countries-checkbox-label'
                id='app-countries-checkbox'
                multiple
                value={countries}
                name='countries'
                onChange={onFilterChange}
                renderValue={(selected) => {
                  let selectedItems = []
                  countryList.forEach((obj) => {
                    if (selected.includes(obj.iso3)) {
                      selectedItems.push(obj.name)
                    }
                  })
                  return selectedItems.join(', ')
                }}
              >
                {(isVaccineOrganization ? countryList.slice(1) : countryList).map((value) => (
                  <MenuItem key={value.iso3} value={value.iso3}>
                    <Checkbox checked={countries?.indexOf(value.iso3) !== -1} />
                    <ListItemText primary={value.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant='standard' sx={{ m: 1, width: 250 }}>
              <InputLabel id='demo-simple-select-autowidth-label'>
                User role
              </InputLabel>
              <Select
                labelId='app-roles-checkbox-label'
                id='app-roles-checkbox'
                multiple
                value={roles}
                name='roles'
                onChange={onFilterChange}
                renderValue={(selected) => {
                  let selectedItems = []
                  userRolesFlattened.forEach((obj) => {
                    if (selected.includes(obj.value)) {
                      selectedItems.push(obj.short_code)
                    }
                  })
                  return selectedItems.join(', ')
                }}
              >
                {(isVaccineOrganization
                  ? userRolesFlattened.filter((val) =>
                      ['vaccine-campaign-admin', 'vaccine-provider'].includes(
                        val.value
                      )
                    )
                  : !isConnectSuperAdmin 
                      ? userRolesFlattened.filter(
                        (val) =>
                          ![
                            'insights-campaign'
                          ].includes(val.value)
                        )
                      : userRolesFlattened.filter(
                      (val) =>
                        ![
                          'vaccine-campaign-admin',
                          'vaccine-provider',
                        ].includes(val.value)
                    )
                ).map((value) => (
                  <MenuItem key={value.value} value={value.value}>
                    <Checkbox checked={roles?.indexOf(value.value) !== -1} />
                    <ListItemText primary={value.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <UserTableList
          filterRoles={roles}
          filterCountries={countries}
          searchKey={searchKey}
        />
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.login.user,
})
export default connect(mapStateToProps, {})(UserList)
