import { AT_SET_NOTIFICATION } from '../actions/types.actions'

const initialState = {
  notification: {
    show: false,
    className: '',
    message: '',
  },
}

const notificationReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case AT_SET_NOTIFICATION:
      return {
        ...state,
        notification: {
          show: payload.is_open,
          className: payload.className,
          message: payload.message,
        },
      }
    default:
      return state
  }
}
export default notificationReducer
