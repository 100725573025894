import axios from 'axios'
import { STORAGE_MANAGEMENT_API_URL } from 'lib/reach52AppsUrls'
import { FILE_UPLOADED_SUCCESSFULLY } from './types.actions'

export const uploadProfilePicture = (file, country) => async (dispatch) => {
  try {
    const FormData = require('form-data')
    const formData = new FormData()
    formData.append('file', file)
    const headersList = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'text/plain',
      'Authorization': localStorage.token && localStorage.user
      ? `Bearer ${localStorage.token}`
      : ''
    }
    const reqOptions = {
      url:`${STORAGE_MANAGEMENT_API_URL}/v2/image/user/profile-picture?isoCountry=${country.toLowerCase()}`,
      method: "POST",
      headers: headersList,
      data: formData,
    }
    const res = await axios.request(reqOptions)
    if (res.status === 200) {
      dispatch({type: FILE_UPLOADED_SUCCESSFULLY, payload: res.data.imageName })
    } else { 
      console.log('error',res.data.message)
    }
  } catch (error) {
    console.log('error',error)
  }
} 
