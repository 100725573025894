import React, { useState } from "react";
import UserRoleBlock from "components/user/UserRoleBlock";
import "styles/user.table.scss";

const UserRoleGroup = ({ roles }) => {
  const [itemsToShow, setItemsToShow] = useState(3);

  const toggleShowAll = () => {
    setItemsToShow(itemsToShow === 3 ? roles.length : 3);
  };

  return (
    <>
      {roles.map((role, idx) => {
        if (idx < itemsToShow) return <UserRoleBlock key={role} role={role} />;
        return null;
      })}
      {roles.length > 3 ? (
        <div onClick={toggleShowAll} className="role-expander">
          •••
        </div>
      ) : null}
    </>
  );
};

export default UserRoleGroup;
