import { Box, Button, Tab } from '@mui/material'
import SideNavBar from 'components/layout/SideNavBar'
import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import TabContext from '@mui/lab/TabContext'
import { TabList, TabPanel } from '@mui/lab'
import {
  checkErrors,
  countFormData,
} from 'components/util/validation/form.validation'
import PropTypes from 'prop-types'
import { updatePassword, updateUser, getUser } from 'actions/user.action'
import { useNavigate } from 'react-router-dom'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'

const initialState = {
  firstname: '',
  lastname: '',
  username: '',
}
const initialPasswordState = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
}
const fieldErrors = {
  firstname: '',
  lastname: '',
  username: '',
  empty: false,
}
const passwordFieldErrors = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
  empty: false,
}
const initialState1 = {
  firstname: '',
  lastname: '',
  username: '',
  email: '',
  country: {},
  organizationId: '',
  organization: [],
  permission: [],
  associate: [],
  userRole: [],
}

const UserProfile = ({
  updateUser,
  updatePassword,
  getUser,
  user: { selectedUser },
}) => {
  const [selectedTab, setSelectedTab] = useState('userUpdateTab')
  const [userFormData, setUserFormData] = useState(initialState)
  const [passwordFormData, setPasswordFormData] = useState(initialPasswordState)
  const [userErrorField, setUserErrorField] = useState(fieldErrors)
  const [passwordVisible, setPasswordVisible] = useState({
    currentPassword: false,
    password: false,
  })
  const [passwordErrorField, setPasswordErrorField] =
    useState(passwordFieldErrors)

  const userId = JSON.parse(localStorage.getItem('user'))?.id

  const navigate = useNavigate()

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem('user'))?.id
    getUser(userId)
  }, [getUser])

  useEffect(() => {
    if (selectedTab === 'userUpdateTab') {
      if (
        selectedUser.username !==
          JSON.parse(localStorage.getItem('user')).username &&
        userId
      ) {
        const userProfile = { ...initialState1 }
        for (const key in selectedUser) {
          if (key in userProfile) userProfile[key] = selectedUser[key]
        }
        for (const key in selectedUser.credential) {
          if (key in userProfile && key !== 'organization') {
            userProfile[key] = selectedUser.credential[key]
          }
        }
        userProfile.id = selectedUser._id

        setUserFormData(userProfile)
      }
    }
  }, [setUserFormData, selectedUser, userId, selectedTab])

  const { firstname, lastname, username } = userFormData
  const { currentPassword, password, confirmPassword } = passwordFormData

  const onChange = (evt) => {
    evt.preventDefault()
    const { name, value } = evt.target
    if (selectedTab === 'userUpdateTab') {
      checkErrors(evt.target, {}, userErrorField, setUserErrorField)
      setUserFormData({
        ...userFormData,
        [name]: value,
      })
    } else {
      checkErrors(
        evt.target,
        passwordFormData,
        passwordErrorField,
        setPasswordErrorField
      )
      setPasswordFormData({
        ...passwordFormData,
        [name]: value,
      })
    }
  }

  const handleTabChange = (e, selectedTabPannel) => {
    setSelectedTab(selectedTabPannel)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (selectedTab === 'userUpdateTab') {
      // fill up all the fields

      if (
        !userFormData.permission.includes('access-web') ||
        userFormData.organization.length === 0
      ) {
        delete userFormData.organization
        userErrorField.organization = ''
      }
      if (
        !(
          userFormData.userRole.includes('vaccine-provider') ||
          userFormData.userRole.includes('vaccine-campaign-admin')
        )
      ) {
        delete userFormData.organizationId
      }
      if (!userFormData.associate.length) {
        delete userFormData.associate
      }
      const country = userFormData.country
      delete userFormData.country
      if (!(countFormData(userFormData) === Object.keys(userFormData).length)) {
        setUserErrorField({
          ...userErrorField,
          empty: true,
        })
        setTimeout(() => {
          setUserErrorField({
            ...userErrorField,
            empty: false,
          })
        }, 2000)
      }
      if (
        countFormData(userFormData) === Object.keys(userFormData).length &&
        countFormData(userErrorField) === 0
      ) {
        let userProfileData = {
          ...userFormData,
        }
        userProfileData.country = country
        delete userProfileData.firstName
        delete userProfileData.lastName

        updateUser(userProfileData, userId, navigate, '/dashboard')
      }
    } else {
      passwordFormData.confirmPassword !== passwordFormData.password
        ? setPasswordErrorField({
            ...passwordErrorField,
            confirmPassword: 'Password does not match',
          })
        : setPasswordErrorField({
            ...passwordErrorField,
            confirmPassword: '',
          })
      if (
        !(
          countFormData(passwordFormData) ===
          Object.keys(passwordFormData).length
        )
      ) {
        setPasswordErrorField({
          ...passwordErrorField,
          empty: true,
        })
        setTimeout(() => {
          setPasswordErrorField({
            ...passwordErrorField,
            empty: false,
          })
        }, 2000)
      }
      if (
        countFormData(passwordFormData) ===
          Object.keys(passwordFormData).length &&
        countFormData(passwordErrorField) === 0 &&
        passwordFormData.confirmPassword === passwordFormData.password
      ) {
        updatePassword(passwordFormData, userId, navigate, '/dashboard')
      }
    }
  }
  return (
    <Fragment>
      <SideNavBar />
      <div className='body-container'>
        <h1 className='body-header-text'>My Profile</h1>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={selectedTab}>
            <TabList
              onChange={handleTabChange}
              aria-label='lab API tabs example'
              textColor='secondary'
              indicatorColor='secondary'
            >
              <Tab
                label='Personal Details'
                className='capitalize'
                value='userUpdateTab'
              />
              <Tab
                label='Update Password'
                className='capitalize'
                value='passwordUpdateTab'
              />
            </TabList>
            <TabPanel value='userUpdateTab'>
              <form className='user-form' onSubmit={onSubmit}>
                <div className='form-field'>
                  <label className='form-label'>First Name</label>
                  <input
                    className='input-default'
                    name='firstname'
                    type='text'
                    placeholder='Enter first name'
                    value={firstname}
                    onChange={onChange}
                  />
                  {userErrorField.firstname && (
                    <label className='validation-error'>
                      {userErrorField.firstname}
                    </label>
                  )}
                </div>

                <div className='form-field'>
                  <label className='form-label'>Last Name</label>
                  <input
                    className='input-default'
                    name='lastname'
                    type='text'
                    placeholder='Enter last name'
                    value={lastname}
                    onChange={onChange}
                  />
                  {userErrorField.lastname && (
                    <label className='validation-error'>
                      {userErrorField.lastname}
                    </label>
                  )}
                </div>

                <div className='form-field'>
                  <label className='form-label'>Username</label>
                  <input
                    className=''
                    name='username'
                    type='text'
                    placeholder='Enter username'
                    value={username}
                    onChange={onChange}
                  />
                  {userErrorField.username && (
                    <label className='validation-error'>
                      {userErrorField.username}
                    </label>
                  )}
                </div>

                <span className='form-field'>
                  {userErrorField.empty && (
                    <label className='validation-error'>
                      Please fill up all the fields
                    </label>
                  )}
                </span>
                <Button
                  onClick={onSubmit}
                  variant='contained'
                  className='submit-button'
                >
                  update
                </Button>
              </form>
            </TabPanel>
            <TabPanel value='passwordUpdateTab'>
              <form className='user-form' onSubmit={onSubmit}>
                <div className='form-field'>
                  <label className='form-label'>Current Password</label>
                  <FormControl sx={{ m: 1, width: '25ch' }}>
                    <OutlinedInput
                      type={
                        passwordVisible.currentPassword ? 'text' : 'password'
                      }
                      value={currentPassword}
                      name='currentPassword'
                      onChange={onChange}
                      onPaste={(e) => {
                        e.preventDefault()
                        return false
                      }}
                      className='input-outline-none'
                      placeholder='Enter current password'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() =>
                              setPasswordVisible({
                                ...passwordVisible,
                                currentPassword:
                                  !passwordVisible.currentPassword,
                              })
                            }
                            edge='end'
                          >
                            {passwordVisible.currentPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  {/* <input
                    className=''
                    name='currentPassword'
                    type='password'
                    placeholder='Enter current password'
                    value={currentPassword}
                    onChange={onChange}
                  /> */}
                  {passwordErrorField.currentPassword && (
                    <label className='validation-error'>
                      {passwordErrorField.currentPassword}
                    </label>
                  )}
                </div>

                <div className='form-field'>
                  <label className='form-label'>New Password</label>
                  <FormControl sx={{ m: 1, width: '25ch' }}>
                    <OutlinedInput
                      type={passwordVisible.password ? 'text' : 'password'}
                      value={password}
                      name='password'
                      onChange={onChange}
                      onPaste={(e) => {
                        e.preventDefault()
                        return false
                      }}
                      className='input-outline-none'
                      placeholder='Enter new password'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() =>
                              setPasswordVisible({
                                ...passwordVisible,
                                password: !passwordVisible.password,
                              })
                            }
                            edge='end'
                          >
                            {passwordVisible.password ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  {/* <input
                    className=''
                    name='password'
                    type='password'
                    placeholder='Enter new password'
                    value={password}
                    onChange={onChange}
                  /> */}
                  {passwordErrorField.password && (
                    <label className='validation-error'>
                      {passwordErrorField.password}
                    </label>
                  )}
                </div>

                <div className='form-field'>
                  <label className='form-label'>Confirm New Password</label>
                  <FormControl sx={{ m: 1, width: '25ch' }}>
                    <OutlinedInput
                      type={
                        passwordVisible.confirmPassword ? 'text' : 'password'
                      }
                      value={confirmPassword}
                      name='confirmPassword'
                      onChange={onChange}
                      onPaste={(e) => {
                        e.preventDefault()
                        return false
                      }}
                      className='input-outline-none'
                      placeholder='Confirm new password'
                    />
                  </FormControl>

                  {/* <input
                    className=''
                    name='confirmPassword'
                    type='password'
                    placeholder='Confirm new password'
                    value={confirmPassword}
                    onChange={onChange}
                  /> */}
                  {passwordErrorField.confirmPassword && (
                    <label className='validation-error'>
                      {passwordErrorField.confirmPassword}
                    </label>
                  )}
                </div>
                <span className='form-field'>
                  {passwordErrorField.empty && (
                    <label className='validation-error'>
                      Please fill up all the fields
                    </label>
                  )}
                </span>
                <Button
                  onClick={onSubmit}
                  variant='contained'
                  className='submit-button'
                >
                  update
                </Button>
              </form>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </Fragment>
  )
}

UserProfile.propTypes = {
  insurers: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  suppliers: state.logisticsVendors.suppliers,
  insurers: state.logisticsVendors.insurers,
  user: state.user,
})

export default connect(mapStateToProps, {
  updateUser,
  updatePassword,
  getUser
})(UserProfile)
