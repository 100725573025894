const userRolesFlattened = [
  {
    label: 'Super Admin',
    value: 'super-admin',
    category: 'reach52 Users',
    color: '#A8C256',
    short_code: 'SUP',
  },
  {
    label: 'App User',
    value: 'app-user',
    category: 'reach52 Users',
    color: '#2B4162',
    short_code: 'APP',
  },
  {
    label: 'Insurance',
    value: 'insurance',
    category: 'Logistics',
    color: '#E47E11',
    short_code: 'INS',
  },
  {
    label: 'Pharmacist',
    value: 'pharmacist',
    category: 'Logistics',
    color: '#E47E11',
    short_code: 'PHM',
  },
  {
    label: 'Pim',
    value: 'pim',
    category: 'Access Web',
    color: '#FB4B4E',
    short_code: 'PIM',
  },
  {
    label: 'Connect Admin',
    value: 'connect-admin',
    category: 'Connect Dashboard',
    color: '#2667FF',
    short_code: 'INA',
  },
  {
    label: 'Manager',
    value: 'connect-manager',
    category: 'Connect Dashboard',
    color: '#2667FF',
    short_code: 'INM',
  },
  {
    label: 'Management Admin',
    value: 'management-admin',
    category: 'Connect Management',
    color: '#826AED',
    short_code: 'MGA',
  },
  {
    label: 'Finance',
    value: 'management-finance',
    category: 'Connect Management',
    color: '#826AED',
    short_code: 'INF',
  },
  {
    label: 'System Admin',
    value: 'management-system-admin',
    category: 'Connect Management',
    color: '#826AED',
    short_code: 'SYA',
  },
  {
    label: 'Tech Admin',
    value: 'management-tech-admin',
    category: 'Connect Management',
    color: '#826AED',
    short_code: 'ITA',
  },
  {
    label: 'Translator',
    value: 'management-translator',
    category: 'Connect Management',
    color: '#43B929',
    short_code: 'IMT',
  },
  {
    label: 'Implementation Manager',
    value: 'management-implementation-manager',
    category: 'Connect Management',
    color: '#826AED',
    short_code: 'IM',
  },
  {
    label: 'Vaccine Campaigner',
    value: 'vaccine-campaign-admin',
    category: 'Connect Vaccination',
    color: '#AC3931',
    short_code: 'VC',
  },
  {
    label: 'Vaccine Organization',
    value: 'vaccine-organisation',
    category: 'Connect Vaccination',
    color: '#AC3931',
    short_code: 'VO',
  },
  {
    label: 'Vaccine Inventory',
    value: 'vaccine-provider',
    category: 'Connect Vaccination',
    color: '#AC3931',
    short_code: 'VI',
  },
  {
    label: 'Insights Super Admin',
    value: 'insights-super-admin',
    category: 'Insights',
    color: '#AC3931',
    short_code: 'CSA',
  },
  {
    label: 'Campaign',
    value: 'insights-campaign',
    category: 'Insights',
    color: '#AC3931',
    short_code: 'CC',
  }
]
export default userRolesFlattened
