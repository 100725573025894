/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-globals */
import React, { useEffect, Fragment, useCallback, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import 'styles/user.table.scss'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  deleteUser,
  loadUsers,
  getUser
} from 'actions/user.action'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { LOADING } from 'actions/types.actions'
import UserRoleGroup from './UserRoleGroup'
import usePagination from 'components/util/Hooks/usePagination.hooks.js'
import Loader from '../util/Loader'
import { TablePagination } from '@mui/material'

const ConnectUserTableList = ({
  loadUsers,
  deleteUser,
  userData,
  getUser,
  loading,
  filterRoles,
  filterCountries,
  searchKey,
  userInfo,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isConnectSuperUser] = useState(
    userInfo.userRole.includes('super-admin') &&
    userInfo.userRole.includes('insights-super-admin')
  )
  const [
    pageIndex,
    pageSize,
    tableData,
    setPageIndex,
    handleChangeRowsPerPage,
    handleChangePage,
  ] = usePagination(userData)

  const getUsersFromApi = useCallback(({isConnectSuperUser}) => {
    loadUsers({ roles: filterRoles, countries: filterCountries, searchKey, getOrganizationLinkedUsers: false,
      userId: userInfo.id, getConnectUsers: isConnectSuperUser })
  }, [userInfo, filterCountries, filterRoles, loadUsers, searchKey]);

  useEffect(() => {
      getUsersFromApi({isConnectSuperUser})
    setPageIndex(0)
  }, [getUsersFromApi, isConnectSuperUser,setPageIndex, userInfo])

  const onDeleteUser = async (id) =>{
    if (confirm('Are you sure you want to delete this user?')) {
      dispatch({
        type: LOADING,
        payload: true,
      })
      await deleteUser(id)
      getUsersFromApi({isConnectSuperUser})
      dispatch({
        type: LOADING,
        payload: false,
      })
    }
  }

  return (
    <Fragment>
      {loading && <Loader show={loading} message='Getting users' />}
      {!loading && (
        <Paper className='table-card-container'>
          <TableContainer className='table-container'>
            <Table
              sx={{ minWidth: 650 }}
              size='small'
              aria-label='a dense table'
            >
              <TableHead className='table-header-border'>
                <TableRow>
                  <TableCell className='table-header'>#</TableCell>
                  <TableCell className='table-header'>Name</TableCell>
                  <TableCell className='table-header'>Country</TableCell>
                  <TableCell className='table-header'>Role</TableCell>
                  <TableCell className='table-header'>Organization</TableCell>
                  <TableCell className='table-header'>Campaign</TableCell>
                  <TableCell className='table-header'>Email</TableCell>
                  <TableCell className='table-header'>Contact No.</TableCell>
                  <TableCell className='table-header'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, idx) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{pageIndex * pageSize + idx + 1}</TableCell>
                    <TableCell className='capitalize'>
                      {row.fullname
                        ? row.fullname
                        : '--'}
                    </TableCell>
                    <TableCell className='capitalize'>
                      {row?.country?.name || '--'}
                    </TableCell>
                    <TableCell>
                      <UserRoleGroup roles={row.credential.userRole} />
                    </TableCell>

                    <TableCell>
                      {row.credential.organization.map((object)=> object.name).join(', ')}
                    </TableCell>
                    <TableCell>
                      {/* for campaign */}
                      {row.credential.campaign ? row.credential.campaign.map((object)=> object.name).join(', '): '--'}
                    </TableCell>
                    <TableCell>
                      {row.credential.email}
                    </TableCell>
                    <TableCell>
                      {row.contactNo ? row.contactNo: '--'}
                    </TableCell>

                    <TableCell>
                      <div className='edit-options-wrapper'>
                        <EditIcon
                          onClick={() => {
                            getUser(row._id)
                            navigate(`/connect-users/profile/${row._id}`)
                          }}
                          className='icon-color-primary edit-option-button'
                        />
                          <DeleteIcon
                            onClick={() => onDeleteUser(row._id)}
                            className='icon-color-red edit-option-button'
                          />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={userData.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      )}
    </Fragment>
  )
}

ConnectUserTableList.propTypes = {
  loadUsers: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  userData: state.user.userData,
  loading: state.user.loading,
  userInfo: state.login.user,
})

export default connect(mapStateToProps, {
  loadUsers,
  deleteUser,
  getUser
})(ConnectUserTableList)
