const appPermission = [
  {
    label: 'Logistics-Pharma',
    value: 'logistics-pharma',
  },
  {
    label: 'Logistics-Insurance',
    value: 'logistics-insurance',
  },
  {
    label: 'Insights',
    value: 'insights',
  },
  {
    label: 'Access-Web',
    value: 'access-web',
  },
  {
    label: 'ASM',
    value: 'asm',
  },
  {
    label: 'Kobo Dashboard',
    value: 'kobodashboard',
  },
  {
    label: 'MIX Panel',
    value: 'mixpanel',
  },
  {
    label: 'Turn IO',
    value: 'turnio',
  },
  {
    label: 'BI Login',
    value: 'bilogin',
  },
  {
    label: 'Connect',
    value: 'connect'
  },
  {
    label: 'Learning Module',
    value: 'learningmodule'
  }
]

export default appPermission
