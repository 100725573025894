import React, { Fragment } from 'react'
import 'styles/Navbar.style.scss'
import Apps from '../admin/Apps'

const Dashboard = () => {
  return (
    <Fragment>
      <div className='body-container'>
        <h1 className='body-header-text'>Apps</h1>
        <Apps />
      </div>
    </Fragment>
  )
}

export default Dashboard
