import {
  USER_LOADED,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
} from '../actions/types.actions'

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated:
    localStorage.getItem('token') && localStorage.getItem('user')
      ? true
      : false,
  loading: true,
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : {},
}

const loginReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      }
    case LOGIN_ERROR:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      }
    default:
      return state
  }
}

export default loginReducer
