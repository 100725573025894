import React from 'react'
import { Logout, Apps, Person, Help, CorporateFare } from '@mui/icons-material'

export const NavMenus = [
  {
    title: 'Apps',
    path: '/dashboard',
    icon: <Apps />,
    cName: 'nav-text',
  },
  {
    title: 'Manage Users',
    path: '/users',
    icon: <Person />,
    cName: 'nav-text',
  },
  {
    title: 'Organization Partners',
    path: '/organization-partners',
    icon: <CorporateFare />,
    cName: 'nav-text',
  },
  {
    title: 'Connect Manage Users',
    path: '/connect-users',
    icon: <Person />,
    cName: 'nav-text',
  },
  {
    title: 'Profile',
    path: '/user/profile',
    icon: <Person />,
    cName: 'nav-text',
  },
  {
    title: 'Help',
    path: '/help',
    icon: <Help />,
    cName: 'nav-text',
  },
  {
    title: 'Logout',
    path: './',
    icon: <Logout />,
    cName: 'nav-text',
  },
]
