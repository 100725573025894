const organizations = [
  // { name: 'TEST RHU', code: 'TEST RHU' },
  // { name: 'Pototan RHU', code: 'Pototan RHU' },
  // { name: 'Cuartero RHU', code: 'Cuartero RHU' }
  { name: 'A Plus Health Centre', code: 'Health Centre' },
  { name: 'Rapid Diagnostic Labs', code: 'Diagnostic Labs' },
  { name: 'City Hospital Partners', code: 'Hospital Partners' },
  { name: 'Health Financing Ltd', code: 'Financing Ltd' }
]

export default organizations
