import React, { Fragment, useState, useEffect } from 'react'
import 'styles/User.form.styles.scss'
import 'styles/Main.body.styles.scss'
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import SideNavBar from 'components/layout/SideNavBar'
import { updateUser, createUser, getUser } from 'actions/user.action'
import { connect } from 'react-redux'
import {
  checkErrors,
  countFormData,
} from 'components/util/validation/form.validation'
import {
  countries,
  userRoles,
  appPermission
} from 'components/util/static/index.static'
import { getCampaigns } from 'actions/campaigns.action'

import {
  TextField,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material'
import ReactGroupSelect from 'react-select'
import Loader from '../util/Loader'
import { FiUpload } from "react-icons/fi";

const initialState = {
  firstname: '',
  lastname: '',
  username: '',
  password: '',
  email: '',
  contactNo: '',
  country: 'all',
  isCampaignRoleRead: 'true',
  permission: [],
  userRole: [],
  campaign: [],
  profilePic: []
}
const fieldErrors = {
  firstname: '',
  lastname: '',
  username: '',
  password: '',
  email: '',
  contactNo: '',
  country: '',
  isCampaignRoleRead: '',
  permission: [],
  userRole: [],
  empty: false,
  campaign: [],
  profilePic: []
}

const connectRoles = [
  {
    label: 'Connect',
    appId: 'connect',
    options: [
      {
        label: 'Campaign',
        value: 'insights-campaign',
      }
    ],
  }
]

const ConnectUserForm = ({
  createUser,
  updateUser,
  getUser,
  user: { selectedUser, vaccineOrganizationUsers, loading },
  getCampaigns,
  campaigns: {campaigns, campaignsLoading},
  userInfo 
}) => {
  const isLoading = loading  && campaignsLoading
  const [userFormData, setUserFormData] = useState({
    ...initialState, 
    permission: ['connect']
  })
  const [userErrorField, setUserErrorField] = useState(fieldErrors)
  const [selectedUserRoles, setSelectedUserRole] = useState([])

  const { userId } = useParams()
  const creatingUser = useMatch('/connect-users/register')
  const navigate = useNavigate()
  const countryISO = selectedUser.country.iso3.toUpperCase()

  useEffect(() => {
    getCampaigns(countryISO.length !== 0 && userId ? countryISO : 'ALL')
  }, [countryISO, getCampaigns, userId])

  useEffect(() => {
    if (selectedUser.username === '' && userId) {
      getUser(userId)
    }
    if (selectedUser && userId) {
      const userProfile = { ...initialState }

      for (const key in selectedUser) {
        if (key in userProfile) {
          if (key==='profilePic'){
            userProfile[key].push(selectedUser[key])
          }else{
            userProfile[key] = selectedUser[key]
          }
        }
      }
      for (const key in selectedUser.credential) {
        if (key in userProfile && (key !== 'organization' || key !== 'campaign')) {
          userProfile[key] = selectedUser.credential[key]
        }
      }
      userProfile['country'] = selectedUser['country'].iso3

      if (selectedUser.credential) {
        if (selectedUser.credential.campaign.length > 0) {
          userProfile.campaign = []
          userProfile.campaign = selectedUser.credential.campaign.map(
            (obj) => obj.campaignId
          )
        }
        if (selectedUser.credential.associate.length > 0) {
          selectedUser.credential.associate.forEach((obj) => {
            if (obj.type === 'campaign') {
              userProfile.isCampaignRoleRead =
                obj.role === 'read' ? 'true' : 'false'
            }
          })
        }
      }
      let userRoleMapping = []
      userRoleMapping = userRoles.reduce(
        (prevValue, roles) => [
          ...prevValue,
          ...roles.options.filter((role) =>
            userProfile.userRole.includes(role.value)
          ),
        ],
        []
      )
      userRoleMapping = userRoleMapping.filter((val)=> ["insights-campaign"].includes(val.value))
      setSelectedUserRole(userRoleMapping)
      userProfile['userRole'] = userRoleMapping.map((val) => val.value)
      setUserFormData(userProfile)
    }
  }, [
    selectedUser,
    vaccineOrganizationUsers,
    getUser,
    userId,
    userInfo
  ])

  const {
    firstname,
    lastname,
    username,
    password,
    email,
    contactNo,
    country,
    isCampaignRoleRead,
    permission,
    userRole,
    campaign,
    profilePic
  } = userFormData

  const userRoleFieldChange = (evt) => {
    const name = 'userRole'
    let value = evt.map((val) => val.value)
    setSelectedUserRole(evt)
    checkErrors({ name, value }, {}, userErrorField, setUserErrorField)
    setUserFormData({
      ...userFormData,
      [name]: value,
    })
  }
  const onFileSelected = (e) => {
    const name = 'profilePic'
    if (e.target.files && e.target.files.length > 0) {
      checkErrors({ name, value: e.target.files }, {}, userErrorField, setUserErrorField)
      setUserFormData({
        ...userFormData,
        [name]: e.target.files
      })
    }
  }

  const onChange = (evt) => {
    const { name, value } = evt.target
    checkErrors(evt.target, {}, userErrorField, setUserErrorField)
    if (name==='country'){
      getCampaigns(value)
    }
    setUserFormData({
      ...userFormData,
      [name]: value,
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()

    // get country object
    let countryObject = countries.find(
      (ctr) => ctr.iso3 === userFormData.country
    )

    // delete password for update function
    if (!creatingUser) delete userFormData.password
    // fill up all the fields
    if (!(countFormData(userFormData) === Object.keys(userFormData).length)) {
      setUserErrorField({
        ...userErrorField,
        empty: true,
      })
      setTimeout(() => {
        setUserErrorField({
          ...userErrorField,
          empty: false,
        })
      }, 2000)
    }
    if (countFormData(userFormData) === Object.keys(userFormData).length && countFormData(userErrorField) === 0) {
      if (userFormData.userRole.includes('insights-campaign')) {
        let userProfile = {
          ...userFormData,
          country: countryObject,
        }
        userProfile.profilePic = profilePic[0]
        let campaignData = []
        if (permission.includes('connect') && userProfile.campaign) {
          campaigns.forEach((obj) => {
            if (userProfile.campaign.includes(obj.campaignId)) {
              campaignData.push({
                campaignId: obj.campaignId,
                name: obj.name
              })
            }
          })
        }

        let associate = []

        if (userRole.includes('insights-campaign')) {
          let associateObj = {}
          associateObj.type = 'campaign'
          associateObj.role =
            isCampaignRoleRead === 'true' ? 'read' : 'read/write'
          associate.push(associateObj)
        }
        userProfile.associate = associate
        userProfile.userRole.push('app-user')
        userProfile.campaign = campaignData
        creatingUser
          ? createUser(userProfile, navigate, true)
          : updateUser(userProfile, userId, navigate, '/connect-users')
      } else {
        setUserErrorField({
          ...userErrorField,
          userRole: 'Please select at least one connect role',
          empty: false,
        })
      }
    }
  }
  
  const formatGroupLabel = (data) => (
    <div className='group-styles'>
      <span>{data.label}</span>
      <span className='group-badge-styles'>{data.options.length}</span>
    </div>
  )

  return (
    <Fragment>
      <SideNavBar />
      <div className='body-container'>
        {isLoading && 
          <Loader show={isLoading} message="Getting user information"/>
        }
        {!isLoading && (
        <Fragment>
          {/* Start of code line */}
          <h1 className='body-header-text'>
            {creatingUser ? 'Create ' : 'Update '} User
          </h1>

          <form className='user-form' onSubmit={onSubmit}>
            <div className='form-field'>
              <label className='form-label'>First Name</label>
              <input
                disabled={!creatingUser}
                className='input-default'
                name='firstname'
                type='text'
                placeholder='Enter first name'
                value={firstname}
                onChange={onChange}
              />
              {userErrorField.firstname && (
                <label className='validation-error'>
                  {userErrorField.firstname}
                </label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Last Name</label>
              <input
                disabled={!creatingUser}
                className='input-default'
                name='lastname'
                type='text'
                placeholder='Enter last name'
                value={lastname}
                onChange={onChange}
              />
              {userErrorField.lastname && (
                <label className='validation-error'>
                  {userErrorField.lastname}
                </label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Username</label>
              <input
                disabled={!creatingUser}
                name='username'
                type='text'
                autoComplete='none'
                placeholder='Enter username'
                value={username}
                onChange={onChange}
              />
              {userErrorField.username && (
                <label className='validation-error'>
                  {userErrorField.username}
                </label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Email</label>
              <input
                disabled={!creatingUser}
                name='email'
                type='text'
                placeholder='Enter email Id'
                value={email}
                onChange={onChange}
              />
              {userErrorField.email && (
                <label className='validation-error'>{userErrorField.email}</label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Contact Number</label>
              <input
                // disabled={!creatingUser}
                name='contactNo'
                type='text'
                placeholder='Enter contact Number'
                value={contactNo}
                onChange={onChange}
              />
              {userErrorField.contactNo && (
                <label className='validation-error'>{userErrorField.contactNo}</label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Upload Profile Picture</label>
              <div className="profile-file-upload border rounded-3">
                <input 
                  disabled={!creatingUser && selectedUser.profilePic !== undefined}
                  type='file' name='profilePic'
                  accept="image/*" 
                  onChange={onFileSelected} />
                <span><FiUpload color="#888" size={20} /></span>
              </div>
              {userErrorField.profilePic && (
                <label className='validation-error'>
                  {userErrorField.profilePic}
                </label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>Country</label>
              <TextField
                id='country'
                select
                name='country'
                value={country}
                onChange={onChange}
              >
                {countries.map((option) => (
                  <MenuItem key={option.iso3} value={option.iso3}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              {userErrorField.country && (
                <label className='validation-error'>
                  {userErrorField.country}
                </label>
              )}
            </div>

            {creatingUser ? (
              <div className='form-field'>
                <label className='form-label'>Password</label>
                <input
                  name='password'
                  type='password'
                  autoComplete='new-password'
                  placeholder='Enter password'
                  value={password}
                  onChange={onChange}
                />
                {userErrorField.password && (
                  <label className='validation-error'>
                    {userErrorField.password}
                  </label>
                )}
              </div>
            ) : (
              ''
            )}

            <div className='form-field'>
              <label className='form-label'>App Permission</label>
              <FormControl sx={{ m: 1 }}>
                <Select
                  labelId='app-permission-checkbox-label'
                  id='app-permission-checkbox'
                  multiple
                  disabled={true}
                  value={permission}
                  name='permission'
                  onChange={onChange}
                  renderValue={(selected) => {
                    let selectedItems = []
                    appPermission.forEach((obj) => {
                      if (selected.includes(obj.value)) {
                        selectedItems.push(obj.label)
                      }
                    })
                    return selectedItems.join(', ')
                  }}
                >
                  {appPermission.map((value) => (
                    <MenuItem key={value.value} value={value.value}>
                      <Checkbox
                        checked={permission.indexOf(value.value) !== -1}
                      />
                      <ListItemText primary={value.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {userErrorField.permission && (
                <label className='validation-error'>
                  {userErrorField.permission}
                </label>
              )}
            </div>

            <div className='form-field'>
              <label className='form-label'>User Role</label>
              <ReactGroupSelect
                isMulti
                options={connectRoles}
                formatGroupLabel={formatGroupLabel}
                value={selectedUserRoles}
                name='userRole'
                onChange={userRoleFieldChange}
              />
              {userErrorField.userRole && (
                <label className='validation-error'>
                  {userErrorField.userRole}
                </label>
              )}
            </div>

            {userRole.includes('insights-campaign') ? (
              <>
                <div className='form-field'>
                  <label className='form-label'>Campaign Role</label>
                  <RadioGroup
                    className='radio-group'
                    value={isCampaignRoleRead}
                    onChange={onChange}
                    name='isCampaignRoleRead'
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label='Read/Write'
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label='Read'
                    />
                  </RadioGroup>
                </div>
              </>
            ) : (
              ''
            )}

            {permission.includes('connect') ? (
              <>
                <div className='form-field'>
                  <label className='form-label'>Campaign</label>
                  <FormControl sx={{ m: 1 }}>
                    <Select
                      labelId='app-campaign-checkbox-label'
                      id='app-campaign-checkbox'
                      multiple
                      value={campaign}
                      name='campaign'
                      onChange={onChange}
                      renderValue={(selected) => {
                        let selectedItems = []
                        campaigns.forEach((obj) => {
                          if (selected.includes(obj.campaignId)) {
                            selectedItems.push(obj.name)
                          }
                        })
                        return selectedItems.join(', ')
                      }}
                    >
                      {campaigns.map((value) => (
                        <MenuItem key={value.campaignId} value={value.campaignId}>
                          <Checkbox
                            checked={campaign.indexOf(value.campaignId) !== -1}
                          />
                          <ListItemText primary={value.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {userErrorField.campaign && (
                    <label className='validation-error'>
                      {userErrorField.campaign}
                    </label>
                  )}
                </div>
              </>
            ) : (
              ''
            )}
            <span className='form-field'>
              {userErrorField.empty && (
                <label className='validation-error'>
                  Please fill up all the fields
                </label>
              )}
            </span>
            <Button
              onClick={onSubmit}
              variant='contained'
              className='submit-button'
            >
              {creatingUser ? 'Create ' : 'Update'} User
            </Button>
          </form>
        {/* End of code line */}
        </Fragment> )} 
      </div>
    </Fragment>
  )
}

ConnectUserForm.propTypes = {
  updateUser: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getCampaigns: PropTypes.func.isRequired,
  campaigns: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user,
  campaigns: state.campaigns,
  userInfo: state.login.user
})

export default connect(mapStateToProps, {
  updateUser,
  createUser,
  getUser,
  getCampaigns
})(ConnectUserForm)
