import React, { useEffect, useState } from 'react'
import 'styles/address.form.styles.scss'
import { Modal, Box, Typography, Button, TextField, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { getManyByLevel, openAddresModal, setAvailableOptions } from 'actions/organization-partner.action'
import { AT_SET_ADDRESS_LIST } from 'actions/types.actions'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  height: '70%',
  bgcolor: 'background.paper',
  border: '2px #000',
  overflow: 'auto',
  p: 4,
}

const AddressModal = ({ selectedAddress, availableOptions, setAvailableOptions, getManyByLevel, 
  addressList, isOpen, openAddresModal, loading, region, notification }) => {
  const [postalcode, setPostalcode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedValues, setSelectedValues] = useState(Array(5).fill(null))
  const dispatch = useDispatch()

  useEffect(() => {
    if(addressList.length === 0) {
      getManyByLevel({ originLevel: 0, level: 1, connectionServer: region })
    }
  }, [region, getManyByLevel])

  const onSubmit = () => {
    for(let i=0; i<5; i++){
      if(selectedValues[i] === null){
        setErrorMessage(`Please choose address level ${i+1}`)
        return
      }
    }
    if(!postalcode || postalcode.length <3 || postalcode.length > 15){
      setErrorMessage(`Please enter a valid postal code`)
      return
    }
    if(isDuplicate()){
      setErrorMessage(`This address is already added, please select different address`)
      return
    }

    setAvailableOptions(Array(5).fill([{id:'',name:''}]))
    dispatch({
      type: AT_SET_ADDRESS_LIST,
      payload: []
    })

    const obj = {
      level_1: selectedValues[0],
      level_2: selectedValues[1],
      level_3: selectedValues[2],
      level_4: selectedValues[3],
      level_5: selectedValues[4],
      postalcode: postalcode
    }
    const currentAddress = [].concat(selectedAddress)
    currentAddress.push(obj)
    openAddresModal(false, currentAddress)
  }

  const onChangeLevel = (level, option) => {
    if(errorMessage !== 'Please enter a valid postal code') {
      setErrorMessage('')
    }
    selectedValues[level-1] = option.name
    for(let i=level; i<5; i++){
      availableOptions[i] = []
      selectedValues[i] = null
    }
    setSelectedValues(selectedValues)
    setAvailableOptions(availableOptions)
    if(level < 5){
      getManyByLevel({ originLevel: level, level: level+1, connectionServer: region, includeEmptyChild: false, parentId: option.id })
    }
  }

  const isDuplicate = () => {
    return selectedAddress.find( (addr) => addr.level_1 === selectedValues[0] &&
        addr.level_2 === selectedValues[1] &&
        addr.level_3 === selectedValues[2] &&
        addr.level_4 === selectedValues[3] &&
        addr.level_5 === selectedValues[4])
  }

  const onClose = () => {
    setAvailableOptions(Array(5).fill([{id:'',name:''}]))
    dispatch({
      type: AT_SET_ADDRESS_LIST,
      payload: []
    })
    if(selectedAddress.length > 0){
      openAddresModal(false, selectedAddress)
    } else {
      openAddresModal(false, [])
    }
  }

  const onChange = (evt) => {
    const { value } = evt.target
    if(errorMessage === 'Please enter a valid postal code') {
      setErrorMessage('')
    }
    setPostalcode(value)
  }

  return (
    <div>
      { !loading  && (<Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select Address
          </Typography>

          { availableOptions.length > 0 ?
            availableOptions.map((options, index) => {
              return (
                <div className="form-field" key={index}>
                  <label className='form-label'>Level { index + 1 }</label>
                  <TextField
                    id='options'
                    select
                    name='options'>
                    { options.map((option) => { 
                      return(
                      <MenuItem 
                        onClick={() => {
                          onChangeLevel(index+1, option)
                        }}
                        key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    )})}
                  </TextField>
                </div> 
              )
            })
          : <div/> }

          <div className='form-field'>
            <label className='form-label'>Postal Code</label>
            <input
              disabled={false}
              className='input-default'
              name='postalcode'
              type='text'
              maxLength="10"
              placeholder='Enter postal code'
              value={postalcode}
              onChange={onChange}
            />
          </div>
          { errorMessage.length > 0 ? (<div className='validation-error'>{errorMessage}</div>) : ('')}
          <div className='action-buttons'>
            <Button className='cancel-button'onClick={onClose}>Cancel</Button>
            <Button className='ok-button' onClick={onSubmit}>OK</Button>
          </div>
        </Box>

      </Modal> )}
    </div>
  )
}

AddressModal.propTypes = {
  getManyByLevel: PropTypes.func.isRequired,
  openAddresModal: PropTypes.func.isRequired,
  setAvailableOptions: PropTypes.func.isRequired,
  selectedAddress: PropTypes.array.isRequired,
  availableOptions: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  addressList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  addressList: state.address.addressList,
  isOpen: state.organizationPartners.isOpen,
  loading: state.address.loading,
  availableOptions: state.address.availableOptions,
  selectedAddress: state.address.selectedAddress,
  region: state.organizationPartners.region,
  notification: state.notification.notification
})

export default connect(mapStateToProps, {
  getManyByLevel,
  openAddresModal,
  setAvailableOptions
})(AddressModal)
